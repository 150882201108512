import React from 'react';
import { WhichIsBest } from '@autovia-uk/polaris-components/components/molecules/WhichIsBest';

const mapWhichIsBest = obj => typeof obj === 'object' && Object.keys(obj).map(el => (obj[el] ? {
  title: obj[el].key,
  value: obj[el].value,
} : false)).filter(item => item !== false && item.title && item.value);

const getTrackingInfo = buttonName => ({
  event: 'marketplace.button_click',
  'event.type': 'GYC',
  'marketplace_button.placement': 'GYCWhichIsBest',
  'marketplace.button': buttonName,
});

export const getWhichIsBest = (vehicleBests, title = 'Which Is Best', extraClassNames = null) => {
  if (vehicleBests === null) {
    return false;
  }

  const columns = [];

  const defaultTooltipsProps = {
    tooltipIcon: 'polaris__ico-info',
    tooltipCloseLabel:
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      d="M10.8287 12.5L6.47189 8.14321L2.32168 12.2934L0.669119 10.6409L4.81932 6.49058L0.5 2.1714L2.17133 0.5L6.49058 4.81925L10.6221 0.687813L12.2747 2.34037L8.14321 6.47181L12.5 10.8286L10.8287 12.5Z"
      fill="#140C19"
    />
  </svg>,
  };

  const cheapest = vehicleBests.cheapest ? mapWhichIsBest(vehicleBests.cheapest) : false;
  if (cheapest) {
    columns.push({
      ...defaultTooltipsProps,
      title: 'Cheapest',
      tooltipTitle: 'Cheapest vehicles',
      tooltipContent: 'Our \'cheapest\' pick is the model with the lowest on the road retail price.',
      features: cheapest,
      marketplaceLink: {
        ...vehicleBests.cheapest.marketplaceLink,
        tracking: getTrackingInfo('Cheapest'),
      },
    });
  }

  const economical = vehicleBests.economical ? mapWhichIsBest(vehicleBests.economical) : false;
  if (economical) {
    columns.push({
      ...defaultTooltipsProps,
      title: 'Most Economical',
      tooltipTitle: 'Most economical vehicles',
      tooltipContent: 'Our \'most economical\' pick is the model with the best fuel economy on the WLTP combined cycle.',
      features: economical,
      marketplaceLink: {
        ...vehicleBests.economical.marketplaceLink,
        tracking: getTrackingInfo('Economical'),
      },
    });
  }

  const fastest = vehicleBests.fastest ? mapWhichIsBest(vehicleBests.fastest) : false;
  if (fastest) {
    columns.push({
      ...defaultTooltipsProps,
      title: 'Fastest',
      tooltipTitle: 'Fastest vehicles',
      tooltipContent: 'Our \'fastest\' pick is the model with the fastest time for the 0-62mph or 0-60mph sprint.',
      features: fastest,
      marketplaceLink: {
        ...vehicleBests.fastest.marketplaceLink,
        tracking: getTrackingInfo('Fastest'),
      },
    });
  }

  const ourPick = vehicleBests.ourPick ? mapWhichIsBest(vehicleBests.ourPick) : false;
  if (ourPick) {
    columns.push({
      ...defaultTooltipsProps,
      title: 'Our Pick',
      tooltipTitle: 'Our pick vehicles',
      tooltipContent: '\'Our pick\' is the model we think offers the best balance of price, specification, economy and performance in the range.',
      features: ourPick,
      marketplaceLink: {
        ...vehicleBests.ourPick.marketplaceLink,
        tracking: getTrackingInfo('Our pick'),
      },
    });
  }

  if (columns.length === 0) {
    return false;
  }

  return (
    <WhichIsBest
      extraClassNames={extraClassNames}
      title={title}
      columns={columns}
    />
  );
};

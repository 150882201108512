import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import get from 'lodash.get';

import {
  HtmlSafe,
  withPagination,
} from 'polaris-coreweb/exports';

import injectionEngine from '@autovia-uk/injection-engine';
import rules from '@autovia-uk/injection-default-rules-set';

import {
  withHelmet,
  withTemplate,
} from 'Protons';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import {
  getArticleGroupContent,
  getIndex,
  getRelatedContent,
  getSimilarProductContent,
  isInDepthCarReview,
  isLongTermTestReview,
  isRoadTestReview,
} from 'Helpers';

import { countBodyInjectedInlineAds } from 'SharedHelpers/countBodyInjectedInlineAds';
import { getPromoBoxSettings } from 'SharedPartialsLocal/getPromoBoxSettings';
import { setPageNumber } from 'SharedHelpers/setPageNumber';

import { getAfterContentAd } from 'SharedPartials/getAfterContentAd';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getIndexArticlePageHeader } from 'SharedPartials/getIndexArticlePageHeader'; // Heading and TaxonomyFilter
import { getReviewMultipageNavigation } from 'SharedPartialsLocal/getReviewMultipageNavigation';
import { getInThisArticle } from 'SharedPartialsLocal/getInThisArticle'; // NextPages
import { getPagination } from 'SharedPartialsLocal/getPagination'; // Pagination
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia'; // Image, Link, Podcast, Social, SocialEmbed, Video, Gallery
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta'; // DisqusCount and PostMeta: Date, Image, Link
import { getReportCard } from 'SharedPartialsLocal/getReportCard';
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import { getSocialSharingButtons } from 'SharedPartialsLocal/getSocialSharingButtons';
import { getSponsorInfo } from 'SharedPartials/getSponsorInfo';
import { getTags } from 'SharedPartialsLocal/getTags';
import { getWhichIsBest } from 'SharedPartialsLocal/getWhichIsBest';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';
import { getBody } from 'SharedPartialsLocal/getBody';
import { getBuyBox } from 'SharedPartialsLocal/getBuyBox';
import { getIndexArticlePageContent } from 'SharedPartials/getIndexArticlePageContent'; // Ad, LoadMore and ArticleGroup
import { getDealsBlock } from '@autovia-uk/polaris-components/components/organisms/DealsBlock/getDealsBlock';

import { ReviewQueryPagination as query } from './ReviewQuery.graphql';

import 'Styles/components/templates/_Review.scss';

const Review = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        adSettings,
        adSettings: { afterContentDesktopAds, afterContentMobileAds },
        promoBoxSettings,
      },
      gycConfig: {
        dealsBlock: {
          review: dealsBlockConfig,
        },
      },
      newsletterConfig,
      siteUrl,
      social: {
        share: {
          platforms: socialSharingPlatforms,
        },
      },
    },
    context: {
      howWeReviewUrl,
    },
    dataLayer,
    layoutData: {
      page: {
        articleType,
        associatedContent,
        authors,
        body,
        breadcrumbs,
        hideTitle,
        filters,
        isSponsored,
        kicker,
        layoutType,
        monetising,
        primaryMedia,
        reviewType,
        showFilters,
        sideContent,
        specsPath,
        sponsor: sponsorInfo,
        subtitle,
        tags,
        title,
        url,
      },
      page,
    },
    isMobile,
  } = props;

  const isBuyingAndLeasing = layoutType === 'buyingAndLeasing';

  const isAdFreeTemplate = layoutType === 'adFreeTemplate';

  // List of makes where we shouldn't display the buy box
  const excludedMakes = ['volvo', 'mercedes'];
  const isExcludedMake = dataLayer.make ? excludedMakes.includes(dataLayer.make.toLowerCase()) : false;

  // List of articleTypes where BuyBox should be displayed
  const isEligibleReviewType = isInDepthCarReview({ articleType, reviewType })
  || isLongTermTestReview({ articleType, reviewType })
  || isRoadTestReview({ articleType, reviewType });

  // Display BuyBox if the article is eligible and the make is not excluded
  const displayBuyBox = isEligibleReviewType && !isExcludedMake;

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const multipage = getRelatedContent(associatedContent, 'relatedPages');
  const hasMultipageNavigation = multipage && (Array.isArray(multipage) && multipage.length !== 0);
  const indexData = getIndex(props);
  const isIndexArticlePage = indexData !== false;
  const dealsBlock = Array.isArray(associatedContent) ? associatedContent.find(({ type }) => type === 'dealsBlock') : null;
  const { newCarDeals } = dealsBlock ?? {};

  let bodyInjected = injectionEngine({
    body,
    rules,
    props: {
      adSettings,
      breakpoints,
      monetising,
      newsletterConfig,
      pageContentType: 'review',
      promoBoxSettings: getPromoBoxSettings({
        promoBoxSettings,
        ppc: promoBoxSettings.subscriptionArticle,
      }),
      sideContent,
    },
  });

  // replace SUBSCRIBE_NEWSLETTER with SHOPWINDOW block
  bodyInjected = bodyInjected && bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const vehicleBests = page && get(page, 'vehicleBests.vehicleWhichIsBestVerdict', null);
  const reportCard = getReportCard({
    props,
    howWeReviewUrl,
    extraClassNames: {
      'polaris__review-report-card': true,
      '-display-buybox': displayBuyBox,
    },
    layout: !displayBuyBox ? 'oneColumn' : undefined, // have pros and cons stacked when the data is on the right side (no buy box)
  });

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    // Return the ShortAuthorBio component
    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
        singleImage
      />
    );
  };

  const bodyTopComponents = (
    <div className="polaris__simple-grid--main">
      {!reportCard && getPrimaryMedia(primaryMedia, '-article')}
      {getSponsorInfo({ sponsor: sponsorInfo })}
    </div>
  );

  return (
    <>
      <div className={`polaris__body${hasMultipageNavigation ? ' -hasMultipageNavigation' : ''}`}>
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            {getBreadcrumbs(breadcrumbs, isSponsored, kicker)}

            {!hideTitle && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}

            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>

            {getPostMeta(page, false, siteUrl, dataLayer, null)}
          </div>

          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation(multipage, url)}
            {(!displayBuyBox && !isBuyingAndLeasing) && (reportCard || null)}
          </div>

          {(
            displayBuyBox
            && !isBuyingAndLeasing
            && reportCard
          ) && (
            <div className="polaris__core-content polaris__content polaris__simple-grid -aligned-top">
              {reportCard}
              {getBuyBox({ props })}
            </div>
          )}

          <div className="polaris__content -body-copy">

            {getBody(
              {
                bodyInjected,
                template: 'review',
                layoutType,
                topComponents: bodyTopComponents,
                isMobile,
              },
            )}

            <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
              {getPagination(associatedContent, url)}
              {multipage && getWhichIsBest(
                vehicleBests,
                'Which Is Best',
              )}
              {getInThisArticle('In This Review', url, multipage, 'currently reading')}
              <div className="polaris__content-bottom">
                {getTags(tags, false)}
                {getSocialSharingButtons({
                  title,
                  siteUrl,
                  url,
                  socialSharingPlatforms,
                })}
              </div>
              {shortAuthorBio()}
            </div>
          </div>
        </main>
      </div>
      <div className="polaris__content polaris__core-content">
        {newCarDeals && getDealsBlock({
          ...newCarDeals,
          config: dealsBlockConfig,
          extraClassNames: {
            '-review': true,
          },
          isScrollable: true,
        })}
      </div>
      {(afterContentDesktopAds || afterContentMobileAds || !isAdFreeTemplate) && (
        getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds })
      )}

      <div className="polaris__content polaris__core-content polaris__core-content-first">
        {getArticleGroup({
          title: 'Have you considered?',
          content: getSimilarProductContent(associatedContent),
          extraClassNames: {
            'polaris__review--consider': true,
            'polaris__core-content': true,
          },
          articleCardProps: {
            datePosition: 'bottom',
            excerpt: null,
            kickerPosition: 'bottom',
            ratingPosition: 'over-image',
            showRating: true,
          },
        })}
        {/* Inject the Native ad in the Most Popular block as the last item */}
        {!isIndexArticlePage
          && (getArticleGroup({
            title: 'Most Popular',
            content: getArticleGroupContent(associatedContent, 'mostPopular', {
              component: [
                <React.Fragment key="native_article">
                  {getNativeAd({
                    id: 'native_article',
                    targeting: {
                      position: 'native_article',
                      placement: 'native_article',
                      refresh: 'no',
                    },
                  })}
                </React.Fragment>,
              ],
            }),
            extraClassNames: { 'polaris__review--most-popular': true },
            articleCardProps: {
              datePosition: 'bottom',
              isHeading: false,
              kickerPosition: 'bottom',
            },
          }))
        }
        {isIndexArticlePage && (
          <>
            {getIndexArticlePageHeader({
              indexData,
              propsData: props,
              showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
              taxonomyFilterParams,
              greyBg: false,
            })}
            {getIndexArticlePageContent(
              indexData,
              {
                ...props,
                articleCardProps: {
                  datePosition: 'bottom',
                  headingSize: 4,
                  kickerPosition: 'bottom',
                  ratingPosition: 'over-image',
                  showRating: true,
                },
                articlesPerRow: 4,
              },
              countInlineAds,
              isMobile,
            )}
          </>
        )}
      </div>
    </>
  );
};

Review.propTypes = {
  config: PropTypes.shape({
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
    globalSettings: PropTypes.shape({
      adSettings: PropTypes.shape({
        afterContentDesktopAds: PropTypes.bool,
        afterContentMobileAds: PropTypes.bool,
        listGalleryAds: PropTypes.number,
        minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
        minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
        minNumOfWordsBeforeTeads: PropTypes.number,
        numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
        numberOfWordsBetweenInlineMobileAds: PropTypes.number,
        numberOfWordsForFirstInjectionDesktop: PropTypes.number,
        numberOfWordsForFirstInjectionMobile: PropTypes.number,
        refreshBlacklist: PropTypes.string,
        refreshSiteWide: PropTypes.bool,
        refreshTime: PropTypes.number,
      }),
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
          enabled: PropTypes.bool,
          service: PropTypes.string,
        }),
      ),
      promoBoxSettings: PropTypes.shape(),
    }).isRequired,
    gycConfig: PropTypes.shape({
      dealsBlock: PropTypes.shape({
        review: PropTypes.shape(),
      }),
    }),
    siteUrl: PropTypes.string.isRequired,
    social: PropTypes.shape({
      share: PropTypes.shape({
        platforms: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          platform: PropTypes.string,
        })),
      }),
    }),
    newsletterConfig: PropTypes.shape(),
  }).isRequired,
  context: PropTypes.shape({
    howWeReviewUrl: PropTypes.string,
  }).isRequired,
  dataLayer: PropTypes.shape({
    make: PropTypes.string,
    product: PropTypes.string,
    productFamily: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
  }),
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf().isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  metaData: PropTypes.shape({
    pageDescription: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    nextUrl: PropTypes.string,
    page: PropTypes.number,
    pages: PropTypes.objectOf(),
    setPageRef: PropTypes.func,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

Review.defaultProps = {
  dataLayer: {},
  location: {
    pathname: '',
    search: '',
  },
};

const MemoReview = React.memo(Review);

export default compose(
  setPageNumber,
  withPagination({
    query,
    path: ({ associatedContent }) => getRelatedContent(associatedContent, 'indexArticlePageList'),
  }),
  withTemplate,
  withPolaris,
  withHelmet('review'),
)(MemoReview);

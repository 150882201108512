/* eslint-disable react/prop-types */
import React from 'react';
import { ArticleGroup } from '@autovia-uk/polaris-components/components/organisms/ArticleGroup';

export const getArticleGroup = ({
  title = null,
  content = [],
  extraClassNames = {},
  articlesPerRow = 4,
  articleCardProps = [{
    datePosition: 'meta',
  }],
  articleCardStyle = 'default',
  blankMessage = null,
  ctaLabel = null,
  ctaUrl = null,
}) => {
  if (blankMessage === null && (title === null || content === null || (Array.isArray(content) && content.length === 0))) {
    return false;
  }

  return (
    <ArticleGroup
      title={title}
      content={content}
      articlesPerRow={articlesPerRow}
      extraClassNames={extraClassNames}
      articleCardProps={articleCardProps}
      articleCardStyle={articleCardStyle}
      blankMessage={blankMessage}
      ctaLabel={ctaLabel}
      ctaUrl={ctaUrl}
    />
  );
};

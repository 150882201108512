import loadable from '@loadable/component';

export const getHelmetForArticle = loadable(() => import('./getHelmetForArticle'));
export const getHelmetForAuthor = loadable(() => import('./getHelmetForAuthor'));
export const getHelmetForContent = loadable(() => import('./getHelmetForContent'));
export const getHelmetForErrorPage = loadable(() => import('./getHelmetForErrorPage'));
export const getHelmetForGallery = loadable(() => import('./getHelmetForGallery'));
export const getHelmetForHome = loadable(() => import('./getHelmetForHome'));
export const getHelmetForIndexTemplate = loadable(() => import('./getHelmetForIndexTemplate'));
export const getHelmetForLoading = loadable(() => import('./getHelmetForLoading'));
export const getHelmetForNotFound = loadable(() => import('./getHelmetForNotFound'));
export const getHelmetForPage = loadable(() => import('./getHelmetForPage'));
export const getHelmetForReview = loadable(() => import('./getHelmetForReview'));
export const getHelmetForSearch = loadable(() => import('./getHelmetForSearch'));
export const getHelmetForSpec = loadable(() => import('./getHelmetForSpec'));

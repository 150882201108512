import React from 'react';
import uniqid from 'uniqid';
import { Subscription } from '@autovia-uk/polaris-components/components/organisms/Subscription';
import 'Styles/components/organisms/_Subscription.scss';

export const getSubscription = (config) => {
  if (!config) return false;

  return (
    <Subscription
      key={uniqid()}
      image={config.image || null}
      mainTitle={config.mainTitle || ''}
      title={config.title || 'Subscribe to Auto Express Magazine'}
      excerpt={config.excerpt || 'Breaking car news and reviews, available as a weekly magazine and on your iPad'}
      ctaLabel={config.ctaLabel || '6 issues for just £1 plus a free gift'}
      ctaUrl={config.ctaUrl || '#'}
      extraClassNames={config.extraClassNames || {}}
    />
  );
};

/* eslint-disable react/prop-types */
import React from 'react';
import { Body } from 'Organisms';

export const getBody = ({
  bodyInjected = [],
  bottomComponents,
  disableAdInjections = false,
  layoutType,
  template = 'article',
  topComponents,
  isMobile,
}) => (
  <Body
    bodyInjected={bodyInjected}
    bottomComponents={bottomComponents}
    disableAdInjections={disableAdInjections}
    layoutType={layoutType}
    template={template}
    topComponents={topComponents}
    isMobile={isMobile}
  />
);

import React from 'react';
import PropTypes from 'prop-types';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import FAQs from './FAQs/FAQs';

import faqsData from './Data/index.json';

export const FAQsWidgetId = 'FAQsModal';

const FAQsWidget = (props) => {
  const {
    data,
  } = props;

  const { faqsProps } = faqsData;

  return (
    <FAQs data={data} {...faqsProps} />
  );
};

FAQsWidget.propTypes = {
  context: PropTypes.shape(),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape()]),
};

FAQsWidget.defaultProps = {
  context: {},
  data: [],
};

export default withPolaris(FAQsWidget);

import { formatPageItemForWhitepaper } from 'Helpers';

export const getArticleContent = (article) => {
  const articleDate = new Date(article.date);
  let contentArticle = {
    image: {
      src: (article.image && article.image.src) ? article.image.src : '',
      alt: (article.image && article.image.alt) ? article.image.alt : '',
    },
    categories: article.categories || [],
    date: `${articleDate.getFullYear()}-${(articleDate.getMonth() + 1).toString().padStart(2, '0')}-${articleDate.getDate().toString().padStart(2, '0')}`,
    component: article.component || null,
    title: article.altTitle || article.title,
    excerpt: article.excerpt || '',
    link: article.link || '#',
    kicker: article.kicker || '',
    rating: article.rating || null,
    isSponsored: article.isSponsored || false,
    mediaType: article.mediaType || 'IMAGE',
  };
  contentArticle = formatPageItemForWhitepaper(contentArticle, article);
  return contentArticle;
};

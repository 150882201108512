import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import {
  getArticleGroupContent,
  getRightSideAdConfig,
} from 'Helpers';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { getNativeAd } from 'SharedPartials/getNativeAd';
import { getBreadcrumbs } from 'SharedPartialsLocal/getBreadcrumbs';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getArticleGroup } from 'SharedPartialsLocal/getArticleGroup';
import { getBody } from 'SharedPartialsLocal/getBody';
import { useLayout } from 'Helpers/hooks/useLayout';

// Template styles and imports
import 'Styles/components/templates/_BasicPage.scss';

const BasicPage = ({
  layoutData: {
    page: {
      layoutType,
      associatedContent,
      body,
      breadcrumbs,
      fullWidth,
      kicker,
      title,
      subtitle,
      isSponsored,
      sponsor: sponsorInfo,
      hideTitle,
    },
  },
  config: {
    breakpoints,
  },
}) => {
  const layout = fullWidth ? 'fullWidth' : layoutType;

  const {
    bodyLandingPageClass,
    fullwidth,
    hideBreadcrumbs,
    hideSubtitle,
    hideSidebar,
    hideMostPopular,
    showTitle,
  } = useLayout(layout);

  let extraClasses = [];

  /**
   * Checks if a block is of a specific type.
   * @param {Object} block - The block to check.
   * @param {string} type - The type to check for.
   * @returns {boolean} True if the block is of the specified type, false otherwise.
   */
  const isBlockType = (block, type) => {
    if (!block) return false;
    if (type === 'INFO_BLOCK') {
      return block.__typename === 'PlaceholderBlock' && block.placeholder === 'INFO_BLOCK';
    }
    if (type === 'PULL_QUOTE_BLOCK') {
      return block.__typename === 'PullQuoteBlock' && block.pullQuoteType === 'PULL_QUOTE_BLOCK';
    }
    return false;
  };

  /**
   * Combines consecutive InfoBlock and PullQuoteBlock pairs into a single block.
   * @param {Array} bodyProp - The array of blocks to process.
   * @returns {Array} The array of combined blocks.
   */
  const combinedBlocks = (bodyProp) => {
    const indicesToRemove = new Set();

    const combinedBody = bodyProp.reduce((accumulatedBody, currentBlock, index) => {
      const nextBlock = bodyProp[index + 1];

      if (isBlockType(currentBlock, 'INFO_BLOCK') && isBlockType(nextBlock, 'PULL_QUOTE_BLOCK')) {
        accumulatedBody.push({
          order: 'info-pullquote',
          infoBlock: currentBlock,
          type: 'INFO_BLOCK_WITH_PULL_QUOTE_BLOCK',
          pullQuoteBlock: nextBlock,
        });
        indicesToRemove.add(index + 1);
      } else if (isBlockType(currentBlock, 'PULL_QUOTE_BLOCK') && isBlockType(nextBlock, 'INFO_BLOCK')) {
        accumulatedBody.push({
          order: 'pullquote-info',
          infoBlock: nextBlock,
          type: 'INFO_BLOCK_WITH_PULL_QUOTE_BLOCK',
          pullQuoteBlock: currentBlock,
        });
        indicesToRemove.add(index + 1);
      } else {
        accumulatedBody.push(currentBlock);
      }

      return accumulatedBody;
    }, []);

    return combinedBody.filter((_, idx) => !indicesToRemove.has(idx));
  };

  const bodyExtended = combinedBlocks(body).map((m) => {
    if (m.type && m.type === 'NEW_CAR_DEALS') {
      extraClasses = [...extraClasses, `-has-embed__${m.type.trim().toLowerCase()}`];
    }

    return { ...m, isSponsored, sponsorInfo };
  });

  // remove duplicates, and convert array in space separated string
  extraClasses = [...new Set(extraClasses)].join(' ');

  const mobileSize = breakpoints?.mobile[0]?.max;
  let isMobile = false;
  if (typeof window !== 'undefined') isMobile = window?.innerWidth <= mobileSize;
  return (
    <>
      <div className={`polaris__body ${extraClasses}${bodyLandingPageClass ? ' -landing-page' : ''}`}>
        <main className="polaris__main" id="main">
          {!hideBreadcrumbs && (
            <div className="polaris__core-content polaris__content">
              <div className="polaris__heading-block--inner">
                {getBreadcrumbs(breadcrumbs, false, kicker)}
              </div>
            </div>
          )}

          <div className={`polaris__core-content polaris__content  ${fullwidth ? '-body-full-width' : 'polaris__simple-grid -simple-basic-page'} -body-copy`}>
            <div className="polaris__simple-grid--main">

              <div className="polaris__text--container">
                {!hideSubtitle && subtitle && (
                  <Heading size={2} extraClassNames={{ 'polaris__article--subtitle': true }}>
                    {subtitle}
                  </Heading>
                )}
                {showTitle && title && !hideTitle && (
                  <Heading size={2} extraClassNames={{ 'polaris__article--subtitle': true }}>
                    {title}
                  </Heading>
                )}
                {getBody({
                  bodyInjected: bodyExtended,
                  template: 'page',
                  layoutType: layout,
                  isMobile,
                })}
              </div>
            </div>

            {!hideSidebar && (
              <div className="polaris__ad-sidebar polaris__simple-grid--aside">
                <Ad {...getRightSideAdConfig()} />
              </div>
            )}
          </div>

          {!hideMostPopular && (
            <div className="polaris__core-content polaris__content polaris__related-content">
              {getArticleGroup({
                title: 'Most Popular',
                content: getArticleGroupContent(associatedContent, 'mostPopular', {
                  component: [
                    <React.Fragment key="native_article">
                      {getNativeAd({
                        id: 'native_article',
                        targeting: {
                          position: 'native_article',
                          placement: 'native_article',
                          refresh: 'no',
                        },
                      })}
                    </React.Fragment>,
                  ],
                }),
                extraClassNames: {
                  'polaris__article--most-popular': true,
                },
                articleCardProps: {
                  datePosition: 'bottom',
                  isHeading: false,
                  kickerPosition: 'bottom',
                },
              })}
            </div>
          )}
        </main>
      </div>
    </>
  );
};

BasicPage.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.objectOf({}).isRequired,
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
  }).isRequired,
  config: PropTypes.shape({
    globalSettings: PropTypes.shape({
      promoBoxSettings: PropTypes.objectOf(
        PropTypes.oneOf([
          PropTypes.string,
          PropTypes.object,
        ]),
      ).isRequired,
    }).isRequired,
    newsletterConfig: PropTypes.shape(),
    breakpoints: PropTypes.shape({
      desktop: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
      mobile: PropTypes.arrayOf(
        PropTypes.shape({
          max: PropTypes.number,
          min: PropTypes.number,
        }),
      ),
    }),
  }).isRequired,
  layoutType: PropTypes.string,
};

BasicPage.defaultProps = {
  layoutType: '',
};

export default compose(
  withTemplate,
  withHelmet('page'),
)(BasicPage);

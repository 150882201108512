import React, { useLayoutEffect } from 'react';

import { Helmet } from 'polaris-coreweb/exports';
import { helmetScripts } from 'Helpers';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { getComponentBlocks } from './getComponentBlocks';

const setWindowVariables = (embedTypes) => {
  embedTypes.forEach((type) => {
    if (type === 'Facebook' && window.FB) {
      window.FB.XFBML.parse();
    } else if (type === 'Twitter' && window.twttr) {
      window.twttr.widgets.load();
    }
  });
};

const Body = (props) => {
  const embedTypes = new Set();
  const blocks = getComponentBlocks(props, embedTypes);

  useLayoutEffect(() => {
    if (embedTypes.size && typeof window !== 'undefined') {
      setWindowVariables(embedTypes);
    }
  });

  return (
    <>
      <Helmet>
        {helmetScripts(embedTypes)}
      </Helmet>
      {blocks}
    </>
  );
};

export default withPolaris(Body);

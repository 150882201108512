import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { SocialEmbed } from '@autovia-uk/polaris-components/components/molecules/SocialEmbed';
import { getBody } from 'SharedPartialsLocal/getBody';
import { Gallery } from '@autovia-uk/polaris-components/components/organisms/Gallery';

import { useScrollTop } from 'Helpers/useScrollTop';
import MobileModelSelectionPage from './MobileModelSelectionPage';
import DesktopModelSelectionPage from './DesktopModelSelectionPage';
import { PAGE_ID_2, PAGE_ID_3 } from '../PageConstants';

import './_style.scss';

const ModelSelectionPage = (props) => {
  const {
    context: { cssPrefix },
    productDetails,
    setCurrentPage,
  } = props;

  let isMobile = false;
  if (typeof window !== 'undefined') isMobile = window?.innerWidth <= 1023;

  if (typeof window !== 'undefined') {
    const sessionStorage = window?.sessionStorage;
    sessionStorage.setItem('pageID', 'PAGE_3_MODEL_SELECTION');
    window.onbeforeunload = () => {
      sessionStorage.clear();
    };
  }

  const sessionStoragePage3 = sessionStorage.getItem(PAGE_ID_3) && JSON.parse(sessionStorage.getItem(PAGE_ID_3));
  const subHeadingFromSessionStorage = sessionStoragePage3?.subHeading;
  const logoFromSessionStorage = sessionStoragePage3?.logo;
  const primaryMediaFromSessionStorage = sessionStoragePage3?.primaryMedia;
  const bodyFromSessionStorage = sessionStoragePage3?.body;

  const brandSubHeading = subHeadingFromSessionStorage || productDetails?.subHeading;
  const contentImage = logoFromSessionStorage || productDetails?.logo;
  const primaryMediaBrandSelectionData = primaryMediaFromSessionStorage || productDetails?.primaryMedia;
  const bodyContent = bodyFromSessionStorage || productDetails?.body;

  const bodyBlocks = getBody({ bodyInjected: bodyContent });

  const testDrive = productDetails?.vehicleNextSteps?.testDrive || sessionStorage.getItem('testDrive');
  const dealer = productDetails?.vehicleNextSteps?.dealer || sessionStorage.getItem('dealer');
  const brochure = productDetails?.vehicleNextSteps?.brochure || sessionStorage.getItem('brochure');
  const hasVehicleNextSteps = testDrive || dealer || brochure;

  const handleClick = (e) => {
    ReactGA.event({
      category: 'Car Discovery Journey',
      action: 'Convertr Form Click',
      label: sessionStorage.getItem('model'),
    });
    return true;
  };

  const modelSelectionVehicleNextSteps = hasVehicleNextSteps && (
    <div className={`${cssPrefix}__actions-section-container`}>
      <div className={`${cssPrefix}__copy-container`}>
        <div className={`${cssPrefix}__copy-container--text-first-line`}>What are you looking for?</div>
        <div className={`${cssPrefix}__copy-container--text-second-line`}>Please select one of the below to continue</div>
      </div>
      <div className={`${cssPrefix}__model-selection-actions`}>
        {testDrive && (
          <Link
            extraClassNames={{ [`${cssPrefix}__model-selection-cta`]: true }}
            href={testDrive}
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleClick}
          >
            Test drive
          </Link>
        )}
        {dealer && (
          <Link
            extraClassNames={{ [`${cssPrefix}__model-selection-cta`]: true }}
            href={dealer}
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleClick}
          >
            General Enquiry
          </Link>
        )}
        {brochure && (
          <Link
            extraClassNames={{ [`${cssPrefix}__model-selection-cta`]: true }}
            href={brochure}
            rel="noopener noreferrer"
            target="_blank"
            onClick={handleClick}
          >
            Car Brochure
          </Link>
        )}
      </div>
    </div>
  );

  const primaryMedia = productDetails?.primaryMedia;
  const primaryMediaPage = primaryMedia?.page;

  const isGallery = primaryMedia && primaryMediaPage?.type === 'Gallery' && Array.isArray(primaryMediaPage?.images) && primaryMediaPage?.images?.length >= 1;
  const isOneImageGallery = primaryMedia && primaryMediaPage?.type === 'Gallery' && Array.isArray(primaryMediaPage?.images) && primaryMediaPage?.images.length === 1;
  const isImage = primaryMedia && primaryMedia?.mediaType === 'Image' && primaryMedia?.src;
  const isSocialEmbed = primaryMedia && primaryMedia?.socialBlockType && primaryMedia?.socialBlockType === 'SOCIAL_EMBED';

  let embedData = null;
  if (isSocialEmbed) {
    embedData = JSON.parse(primaryMediaBrandSelectionData.data);
  }

  let productMedia = '';
  if (isGallery) {
    productMedia = (
      <Gallery
        mode="swipe"
        displayArrows={!isOneImageGallery}
        cover={{
          src: primaryMediaPage?.images[0].large,
          alt: primaryMediaPage?.images[0].alt,
        }}
        media={primaryMediaPage?.images}
      />
    );
  } else if (isImage) {
    productMedia = (
      <Image
        alt={primaryMedia?.alt}
        extraClassNames={{ [`${cssPrefix}__model-selection-image`]: true }}
        size="primary-image"
        src={primaryMedia?.src}
      />
    );
  } else if (isSocialEmbed && embedData !== null) {
    productMedia = (
      <div className={`${cssPrefix}__model-selection-social-embed`}>
        <SocialEmbed
          html={embedData.html}
          type={embedData.__data?.type?.toLowerCase()}
          url={embedData.__data?.url}
        />
      </div>
    );
  }

  const brandDetails = (
    <>
      {productDetails && productDetails?.subHeading && (
        <p className={`${cssPrefix}__model-header-text`}>{brandSubHeading}</p>
      )}
      {productDetails && productDetails?.logo && (
        <Image
          extraClassNames={{ [`${cssPrefix}__brand-image`]: true }}
          size="sponsor"
          src={contentImage.src}
        />
      )}
    </>
  );

  const extraProps = {
    ...props,
    brandDetails,
    bodyBlocks,
    primaryMedia: productMedia,
    vehicleNextSteps: modelSelectionVehicleNextSteps,
  };

  useScrollTop();

  const useBrowserBackButton = () => {
    const [isBackButtonClicked, setBackButtonClicked] = useState(false);

    const onBackButtonEvent = (e) => {
      e.preventDefault();

      if (!isBackButtonClicked) {
        setBackButtonClicked(true);
        setCurrentPage(PAGE_ID_2);
        window.sessionStorage.setItem('pageID', 'PAGE_2_BRAND_SELECTION');
      } else {
        window.history.pushState(null, null, window.location.href);
        setBackButtonClicked(false);
      }
    };

    useEffect(() => {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }, []);
  };

  useBrowserBackButton(setCurrentPage);

  return (
    <div className={`${cssPrefix}__newCarDeals--page-3`}>
      {isMobile ? <MobileModelSelectionPage {...extraProps} />
        : <DesktopModelSelectionPage {...extraProps} />}
    </div>
  );
};

ModelSelectionPage.defaultProps = {
  context: {},
  productDetails: {},
};

ModelSelectionPage.propTypes = {
  context: PropTypes.shape(),
  productDetails: PropTypes.shape(),
  setCurrentPage: PropTypes.func.isRequired,
};

export default withPolaris(ModelSelectionPage);

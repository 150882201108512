/* eslint-disable react/prop-types */
import React from 'react';
import uniqid from 'uniqid';
import { Newsletter } from '@autovia-uk/polaris-components/components/organisms/Newsletter';

export const getNewsletter = (props = {}) => {
  if (typeof props !== 'object' || Object.keys(props).length === 0) {
    return false;
  }

  const {
    mainTitle,
    title,
    ctaLabel,
    url: formUrl,
    placeholder,
    modalOpen,
    subtitle,
    extraClassNames,
  } = props;

  return (
    <Newsletter
      mainTitle={mainTitle}
      title={title}
      cta_label={ctaLabel}
      formUrl={formUrl}
      placeholder={placeholder}
      modalOpen={modalOpen || false}
      subtitle={subtitle}
      extraClassNames={extraClassNames}
      key={uniqid()}
    />
  );
};

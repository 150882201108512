import React from 'react';
import PropTypes from 'prop-types';

import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { withPolaris } from 'Components/protons/Polaris';

import { GYCLandingPageData } from './GYCLandingPageData';

import './_style.scss';

const GYCLandingPageBanner = ({
  context: {
    cssPrefix,
  },
}) => {
  const bannerData = GYCLandingPageData?.banner;
  return (
    <Hero
      title={bannerData?.title}
      subtitle={bannerData?.subtitle}
      images={[
        {
          src: bannerData?.imageUrlDesktop,
          size: 'hero-feature-desktop',
        },
        {
          src: bannerData?.imageUrlMobile,
          size: 'hero-feature-mobile',
        },
      ]}
      renderPictureTag
      showPrimaryMedia
      extraClassNames={{
        [`${cssPrefix}__gyc-landing-page--banner`]: true,
      }}
    >
      <Link
        href={bannerData?.ctaUrl}
        ariaLabel={bannerData?.ctaLabel}
      >
        {bannerData?.ctaLabel}
      </Link>
    </Hero>
  );
};

GYCLandingPageBanner.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

GYCLandingPageBanner.defaultProps = {
  context: {},
};

export default withPolaris(GYCLandingPageBanner);

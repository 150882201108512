import React, { Fragment } from 'react';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { getRatings } from 'SharedPartialsLocal/getRatings';
import { Tabs } from '@autovia-uk/polaris-components/components/molecules/Tabs';

const getContent = (body, ratingsForCurrentReview, review, keyForFragment) => (
  <Fragment key={keyForFragment}>
    <HtmlSafe html={body} />
    {getRatings(ratingsForCurrentReview)}
    <HtmlSafe html={review} />
  </Fragment>
);

const getTabsContent = ownerReviewData => ownerReviewData.map((el, index) => {
  const {
    body,
    ratings: ratingsForCurrentReview,
    review,
    title,
  } = el;

  const keyForFragment = `tab_${index}`;
  const content = getContent(body, ratingsForCurrentReview, review, keyForFragment);

  return {
    title,
    content,
  };
});

export const getTabs = ownerReviewData => (
  <Tabs
    tabs={getTabsContent(ownerReviewData)}
    visibleTabIndex={0}
  />
);

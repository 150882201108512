export const GYCLandingPageData = {
  banner: {
    title: 'Find A Car',
    subtitle: 'Get a great deal with the help of the experts.',
    ctaLabel: 'Build A Car',
    ctaUrl: 'https://marketplace.autoexpress.co.uk/buy-a-car',
    imageUrlMobile: 'https://media.evo.co.uk/image/private/s--uThc1k3R--/v1730724101/autoexpress/2024/11/Range%20Rover%20v%20Volvo-18_780x488_eztxvo.jpg',
    imageUrlDesktop: 'https://media.evo.co.uk/image/private/s--bGDqYWFK--/v1730724103/autoexpress/2024/11/Range%20Rover%20v%20Volvo-18_qxz3il.jpg',
  },
};

import {
  compose,
  withProps,
  withState,
} from 'recompose';

import { withPagination } from 'polaris-coreweb/exports';

import {
  withHelmet,
  withTemplate,
} from 'Protons';

import { SearchQueryPagination as query } from './SearchQuery.graphql';
import SearchTemplate from './SearchTemplate';

const ComposedSearchTemplate = compose(
  withState(
    'searchPath',
    'setSearchPath',
    ({ location: { pathname } }) => pathname,
  ),
  withProps(({ searchPath }) => ({ location: { pathname: searchPath } })),
  withPagination({ query }),
  withTemplate,
  withHelmet('search'),
)(SearchTemplate);

export default ComposedSearchTemplate;

export const SellMyCarData = {
  sellYourCarBanner: {
    title: 'Sell your car with Carwow',
    desc: 'Thinking about selling? We\'re working with the car-changers at Carwow, giving you access to their '
      + 'nationwide network. Over 4,500 verified dealers, ready to bid, equals a great price for you. '
      + '<br /><br />'
      + 'Here\'s how it works: fill in some details about your car. Get a valuation in minutes. Once it\'s listed, '
      + 'you\'re ready to start receiving offers… all for free.',
    bannerUrl: '/public/partners/partner--carwow--landing-bg.png',
    ctaLabel: 'Get started',
    imageUrl: '/public/partners/partner--carwow-stack.svg',
    inputLabel: '',
    inputPlaceholder: 'ENTER REG',
    ctaUrl: 'https://www.carwow.co.uk/sell-my-car?utm_source=autoexpress&utm_medium=content_partner&utm_campaign=smc_landing_page&vrm=[vrm]',
    linkLabel: 'Get started',
  },
  howDoISellMyCar: {
    title: 'How do I sell my car?',
    list: [{
      title: 'Enter your reg number',
      desc: 'You\'ll get a free valuation in minutes. Then add some more details about your car to ensure you get a great deal.',
    }, {
      title: 'Carwow does its thing',
      desc: 'Once you\'ve set a reserve price, Carwow notifies its network of over 4,500 dealers, '
        + 'who are ready to start bidding on your car.',
    }, {
      title: 'Wait for the highest bidder...',
      desc: '... and the deal\'s done!',
    }, {
      title: 'Sold!',
      desc: 'Your winning dealer will be in touch to collect your car and arrange payment. It\'s as easy as that.',
    }],
  },
  whatIs: {
    inputPlaceholder: 'ENTER REG',
    ctaUrl: 'https://www.carwow.co.uk/sell-my-car?utm_source=autoexpress&utm_medium=content_partner&utm_campaign=smc_landing_page&vrm=[vrm]',
    linkLabel: 'Get started',
    title1: 'What is Carwow?',
    desc: 'Carwow is the one-stop shop for changing your car online. With expert advice, over 500 models and a network '
      + 'of over 4,500 verified dealers, it makes changing cars easy – whether you\'re buying or selling.',
    title2: 'Why sell your car with Carwow?',
    list: [
      {
        icon: '/public/sell-your-car.png',
        heading: 'It’s easy, free and all online.',
        detail: 'There are no hidden fees, or costs. All you have to do is enter your reg number and mileage, '
          + 'and upload pictures of your car. Carwow does the rest, including collecting your documents.',
      },
      {
        icon: '/public/car-valuation.png',
        heading: 'You\'ll get a trusted valuation and a great price.',
        detail: 'Carwow valuations are based on the latest market data, but you also set a reserve price. '
          + 'With 4,500 dealers ready to bid for your car, the offers are always competitive.',
      },
      {
        icon: '/public/sell-your-car-fast.png',
        heading: 'Sell your car fast.',
        detail: 'Carwow\'s dealers are bidding daily, making the process super quick.',
      },
    ],
    imageUrl: '/public/partners/partner--carwow-stack.svg',
  },
};

import { getNavigationConfig } from './getNavigationConfig';

export const getFooterConfig = (config) => {
  if (!config) {
    return {};
  }

  const navigation = getNavigationConfig(config);

  const legalDisclaimer = config?.legalDisclaimer?.footer;

  return {
    legalDisclaimer,
    navigation,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Helmet,
  withConfig,
} from 'polaris-coreweb/exports';

import { Breadcrumb } from '@autovia-uk/polaris-components/components/molecules/Breadcrumb';

const BreadcrumbWrapper = (props) => {
  const { content, config: { siteUrl } } = props;
  if (!content.length) {
    return (
      <>
        <Breadcrumb
          {...props}
        />
      </>
    );
  }
  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <script type="application/ld+json" merge="false">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${
                content.map((item, index) => (`{
                      "@type": "ListItem",
                      "position": ${index + 1},
                      "name": "${item.title}",
                      "item": "${siteUrl + item.url}"
                    }`))
          }]
            }
          `}
        </script>
      </Helmet>
      <Breadcrumb
        {...props}
      />
    </>
  );
};

BreadcrumbWrapper.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default withConfig(BreadcrumbWrapper);

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'polaris-coreweb/exports';
import { getPaginatedTitle, toAbsolute } from 'Helpers';

const getHelmetForAuthor = (props) => {
  const {
    layoutData: {
      page: {
        author: {
          bio,
          firstName,
          jobTitle,
          lastName,
          name,
          photo,
          social,
        },
        url,
      },
    },
    pagination: {
      page: currentPage,
    },
    config: {
      siteUrl,
    },
  } = props;

  const strippedBio = bio ? bio.replace(/(<([^>]+)>)/gi, '') : '';

  return (
    <Helmet>
      <title>{getPaginatedTitle(name, currentPage)}</title>
      <script type="application/ld+json">
        {JSON.stringify({
          '@type': 'Person',
          '@context': 'http://schema.org/',
          name,
          jobTitle: jobTitle || undefined,
          description: strippedBio,
          image: photo && photo.src ? {
            '@type': 'ImageObject',
            url: photo.src,
            height: photo.height || undefined,
            width: photo.width || undefined,
          } : undefined,
          url: url && toAbsolute(url, siteUrl),
          sameAs: (social && social.length > 0 && social.map(singleSocial => singleSocial.url)) || undefined,
        })}
      </script>
      <meta property="twitter:description" content={strippedBio} />
      <meta property="twitter:title" content={name} />
      <meta property="og:type" content="profile" />
      <meta property="og:profile:first_name" content={firstName} />
      <meta property="og:profile:last_name" content={lastName} />
      {photo?.src ? <meta property="og:image" content={photo?.src} /> : ''}
      <meta property="og:description" content={strippedBio} />
      <meta property="og:title" content={name} />
    </Helmet>
  );
};

getHelmetForAuthor.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      author: PropTypes.shape({
        bio: PropTypes.string,
        firstName: PropTypes.string,
        jobTitle: PropTypes.string,
        lastName: PropTypes.string,
        name: PropTypes.string,
        photo: PropTypes.shape({
          height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          src: PropTypes.string,
          width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        social: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
        url: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
};

export default getHelmetForAuthor;

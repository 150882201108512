import React from 'react';
import PropTypes from 'prop-types';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import HowDoISellMyCar from './HowDoISellMyCar';
import SellYourCarBanner from './SellYourCarBanner';
import WhatIs from './WhatIs';

import './_style.scss';

export { SellMyCarData } from './data';
export const SellMyCarWidgetId = 'SELL_MY_CAR';

const SellMyCarLandingPage = (props) => {
  const {
    context: {
      cssPrefix,
    },
    impactUrl,
    howDoISellMyCar,
    sellYourCarBanner,
    whatIs,
  } = props;

  const { title, desc } = sellYourCarBanner;

  return (
    <div className={`${cssPrefix}__sell-my-car-landing`}>
      <SellYourCarBanner title={title} desc={desc} impactUrl={impactUrl} />
      <div className={`${cssPrefix}__sell-my-car-container`}>
        <HowDoISellMyCar {...howDoISellMyCar} />
        <WhatIs {...whatIs} />
      </div>
    </div>
  );
};

SellMyCarLandingPage.propTypes = {
  context: PropTypes.shape(),
  impactUrl: PropTypes.string,
  howDoISellMyCar: PropTypes.shape(),
  sellYourCarBanner: PropTypes.shape(),
  whatIs: PropTypes.shape(),
};

SellMyCarLandingPage.defaultProps = {
  context: {},
  impactUrl: '',
  howDoISellMyCar: {},
  sellYourCarBanner: {},
  whatIs: {},
};

export default withPolaris(SellMyCarLandingPage);

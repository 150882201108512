import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { ModalPopup } from 'Organisms/ModalPopup';

import './_style.scss';

const FAQs = (props) => {
  const {
    context: {
      cssPrefix,
    },
    data,
    title,
  } = props;

  const [showDetail, setShowDetail] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isHideModal, setIsHideModal] = useState(true);

  const handleToggle = (item) => {
    setShowDetail(!showDetail);
    setModalContent(item.answer);
    setIsHideModal(false);
  };

  const handleCloseModal = () => {
    setIsHideModal(true);
    setShowDetail(false);
  };

  const hasData = ((Array.isArray(data)) && data.length !== 0);

  // structured data
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: data && Array.isArray(data) && data.map(l => ({
      '@type': 'Question',
      name: l.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: l.answer,
      },
    })),
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      </Helmet>
      <ModalPopup isHideModal={isHideModal} onCloseModal={handleCloseModal}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={`${cssPrefix}__faq-modal`} dangerouslySetInnerHTML={{ __html: modalContent }} />
      </ModalPopup>
      <div className={`${cssPrefix}__faqs-section`}>
        <div className={`${cssPrefix}__faqs-section--title`}>{title}</div>
        <div className={`${cssPrefix}__content`}>
          {hasData && (
          <div className={`${cssPrefix}__faqs-section--list`}>
            {data && data.map((item, index) => (
              <div
                className={`${cssPrefix}__faqs-section--list-item`}
                key={`faqs-item-${index}`}
              >
                <div className={`${cssPrefix}__faqs-section--list-item--question`}>
                  {item.question}
                </div>
                <div className={`${cssPrefix}__faq-action-button`}>
                  <Image
                    onClick={() => handleToggle(item)}
                    src={!showDetail ? '/public/Open.svg' : '/public/Close.svg'}
                    alt={!showDetail ? 'open' : 'close'}
                    lazyLoad
                  />
                </div>
                <div className="screen-reader-text">
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </>
  );
};

FAQs.propTypes = {
  context: PropTypes.shape(),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.shape()]),
  title: PropTypes.string,
};

FAQs.defaultProps = {
  context: {},
  data: [],
  title: null,
};

export default withPolaris(FAQs);

import { compose } from 'recompose';

import { withPagination } from 'polaris-coreweb/exports';
import { getPaginationLimit } from 'SharedPartialsLocal/getPaginationLimit';
import {
  withHelmet,
  withTemplate,
} from 'Protons';

import { AuthorQueryPagination as query } from './AuthorQuery.graphql';
import { Author } from './Author';

export default compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withTemplate,
  withHelmet('author'),
)(Author);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';

import { useScrollTop } from 'Helpers/useScrollTop';
import { useDataFetch } from 'SharedHooks/useDataFetch';
import NewCarDealsQuery from '../NewCarDealsQuery.graphql';
import * as pageConstants from '../PageConstants';
import { allBrandsPageProductData } from '../handleQueryData';

import './_style.scss';

const AllBrandsPage = (props) => {
  const {
    content,
    context: { cssPrefix },
    setCurrentPage,
    layout,
  } = props;

  if (typeof window !== 'undefined') {
    const sessionStorage = window?.sessionStorage;
    sessionStorage.setItem('pageID', 'PAGE_1_ALL_BRANDS');
    window.onbeforeunload = () => {
      sessionStorage.clear();
    };
  }

  useScrollTop();

  const { state: productDataFetchState, fetchData } = useDataFetch({ query: NewCarDealsQuery });

  useEffect(() => {
    allBrandsPageProductData(productDataFetchState, setCurrentPage, pageConstants.PAGE_ID_2);
  }, [productDataFetchState.isSuccess, productDataFetchState.data]);

  const [isBackButtonClicked, setBackButtonClicked] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (!isBackButtonClicked) {
      setBackButtonClicked(true);
      window.history.go(-1);
    } else {
      window.history.pushState(null, null, window.location.href);
      setBackButtonClicked(false);
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const getProductDetailsData = (brandId) => {
    const { sitename } = window.__API_CREDENTIALS__;
    sessionStorage.setItem('productId', brandId);
    fetchData({ variables: { sitename, id: brandId, url: window.location.pathname } });
  };

  const handleClick = (brandId, brandName) => {
    ReactGA.event({
      category: 'Car Discovery Journey',
      action: 'Manufacturer Selection Click',
      label: brandName,
    });
    getProductDetailsData(brandId);
  };

  return (
    <div className={`${cssPrefix}__newCarDeals--page-1 ${layout}`}>
      {layout === 'default' && (
        <div className={`${cssPrefix}__title-container`}>
          <p>Find your next car</p>
          <div className={`${cssPrefix}__subtitle-container`}>
            <p>Explore the latest models from the UK&#39;s top car brands…</p>
          </div>
        </div>
      )}
      <div className={`${cssPrefix}__brand-content`}>
        {content.map((brand, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className={`${cssPrefix}__brand-link`}
            key={`brand-${index}`}
            {... layout === 'default' && { onClick: () => handleClick(brand.id, brand.title) }}
            role="button"
            tabIndex="0"
          >
            {brand.image && (
              <Image
                extraClassNames={{ [`${cssPrefix}__brand-image`]: true }}
                size="sponsor"
                src={brand.image.src}
              />
            )}
            {layout === 'default' && (
              <div className={`${cssPrefix}__brand-title`}>
                {brand.title}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

AllBrandsPage.defaultProps = {
  content: [],
  context: {
    cssPrefix: 'polaris',
  },
  setCurrentPage: () => {},
  layout: 'default',
};

AllBrandsPage.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf({
      id: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.objectOf({
        id: PropTypes.string,
        publicId: PropTypes.string,
        format: PropTypes.string,
        version: PropTypes.number,
        url: PropTypes.string,
        secureUrl: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        size: PropTypes.number,
        created: PropTypes.string,
        src: PropTypes.string,
        fullDistributionRights: PropTypes.bool,
        __typename: PropTypes.string,
      }),
    })),
    PropTypes.array,
  ]),
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  setCurrentPage: PropTypes.func,
  layout: PropTypes.string,
};

export default withPolaris(AllBrandsPage);

import React from 'react';
import loadable from '@loadable/component';
import uniqid from 'uniqid';

export const getPodcast = (podcastData) => {
  const Podcast = loadable(() => import(/* webpackPrefetch: true */'@autovia-uk/polaris-components/components/molecules/Podcast/Podcast'));
  return (
    <Podcast {...podcastData} key={uniqid()} />
  );
};

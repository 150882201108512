import { compose } from 'recompose';

import { withPagination } from 'polaris-coreweb/exports';
import { getPaginationLimit } from 'SharedPartialsLocal/getPaginationLimit';

import {
  withHelmet,
  withTemplate,
} from 'Protons';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';


import { IndexQueryPagination as query } from './IndexQuery.graphql';
import IndexTemplate from './IndexTemplate';

const ComposedIndexTemplate = compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withTemplate,
  withPolaris,
  withHelmet('index'),
)(IndexTemplate);

export default ComposedIndexTemplate;

import PropTypes from 'prop-types';
import React from 'react';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import './_style.scss';

const WhatIs = (
  props,
) => {
  const {
    context: {
      cssPrefix,
    },
    desc,
    list,
    title1,
    title2,
  } = props;

  return (
    <div className={`${cssPrefix}__whatIs`}>
      <hr />
      <div className={`${cssPrefix}__whatIs-innerWrapper`}>
        <div>
          <div className={`${cssPrefix}__whatIs-desc`}>
            <h3>{title1}</h3>
            <p>
              {desc}
            </p>
          </div>
        </div>
        <div className={`${cssPrefix}__whatIs-title`}>
          <h3>{title2}</h3>
          {list && list.map((item, index) => (
            <div className={`${cssPrefix}__whatIs-list`} key={`whatIs_${index}`}>
              <div className={`${cssPrefix}__whatIs-icon`}>
                <img src={item.icon} alt={item.icon} />
              </div>
              <div className={`${cssPrefix}__whatIs-detail`}>
                <div className={`${cssPrefix}__whatIs-detail-title`}>
                  {item.heading}
                </div>
                <p>{item.detail}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

WhatIs.propTypes = {
  context: PropTypes.shape(),
  desc: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({
    detail: PropTypes.string,
    icon: PropTypes.string,
    heading: PropTypes.string,
  })),
  title1: PropTypes.string,
  title2: PropTypes.string,
};

WhatIs.defaultProps = {
  context: {},
  desc: '',
  list: [],
  title1: '',
  title2: '',
};

export default withPolaris(WhatIs);

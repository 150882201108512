/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

import { getPrimaryMediaPreloadLink } from 'SharedPartialsLocal/getPrimaryMediaPreloadLink';

const getHelmetForHome = (props) => {
  const {
    layoutData: {
      page: pageData,
    },
    config: {
      globalSettings: {
        siteDescription,
        siteTitle,
      },
      siteUrl,
    },
    metaData: {
      pageTitle = '',
      pageDescription = '',
    },
    context: { imageSizesCallback },
  } = props;

  return (
    <Helmet titleTemplate="%s">
      <title>{`${siteTitle} | ${siteDescription}`}</title>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <script type="application/ld+json" merge="false">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          name: pageTitle,
          description: pageDescription,
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/search/{search_term}/`,
            'query-input': 'required name=search_term',
          },
        })}
      </script>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <script type="application/ld+json" merge="false">
        {JSON.stringify({
          '@id': '#main-org',
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Dennis Publishing',
          url: 'https://www.dennis.co.uk/',
          logo: 'https://www.dennis.co.uk/wp-content/uploads/2017/03/Dennis_BD_CMYK_Logo_BLK.svg',
        })}
      </script>
      {getPrimaryMediaPreloadLink(pageData, imageSizesCallback)}
    </Helmet>
  );
};

getHelmetForHome.propTypes = {
  config: PropTypes.shape({
    globalSettings: PropTypes.shape({
      siteDescription: PropTypes.string,
      siteTitle: PropTypes.string,
    }),
    siteTitle: PropTypes.string,
    siteUrl: PropTypes.string,
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }),
  context: PropTypes.shape().isRequired,
};

getHelmetForHome.defaultProps = {
  metaData: {},
};

export default getHelmetForHome;

import { getNextPages } from 'SharedPartialsLocal/getNextPages';

import {
  getRelatedContent,
} from 'Helpers';

export const getArticleBottomComponents = ({
  associatedContent,
  url,
}) => {
  const bottomComponents = [];
  const multipage = getRelatedContent(associatedContent, 'relatedPages');

  const nextPages = getNextPages({
    multipage, currentUrl: url, title: 'Continue Reading',
  });

  bottomComponents.push(nextPages);
  return bottomComponents;
};

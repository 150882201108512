/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

import { getPrimaryMediaPreloadLink } from 'SharedPartialsLocal/getPrimaryMediaPreloadLink';

const getHelmetForGallery = (props) => {
  const {
    layoutData: {
      page: pageData,
      page: {
        title,
      },
    },
    context: { imageSizesCallback },
  } = props;

  return (
    <Helmet>
      <title>{title}</title>
      {getPrimaryMediaPreloadLink(pageData, imageSizesCallback)}
    </Helmet>
  );
};

getHelmetForGallery.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  context: PropTypes.shape().isRequired,
};

export default getHelmetForGallery;

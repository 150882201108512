import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';

import {
  getAuthors,
  getPaginatedTitle,
  getMappedContentType,
  toAbsolute,
} from 'Helpers';

/**
 * Set Meta tag in the header
 * @returns {*}
 */
const getHelmetForContent = (props) => {
  const mappedContentType = getMappedContentType(props);

  const {
    layoutData: {
      page: pageData,
      page: {
        altTitle,
        authors: rawAuthors,
        teaserImage,
        date: dateModified,
        created: datePublished,
      },
    },
    metaData: {
      pageTitle: metaTitle,
      pageDescription: metaDescription,
      ogImage: metaImage,
    },
    dataLayer: {
      product,
      productFamily,
      make,
    },
    pagination: { page },
    config: {
      siteUrl,
      header: {
        logoImage,
      },
    },
  } = props;


  let author;
  if (rawAuthors.length) {
    const { author: authorName, authorImage: { src: authorImage } } = getAuthors(Array.isArray(rawAuthors) ? [rawAuthors[0]] : rawAuthors);
    author = {
      '@type': 'Person',
      name: authorName,
    };
    if (authorImage) {
      author.image = toAbsolute(authorImage, siteUrl);
    }
  } else {
    author = {
      '@type': 'Person',
      name: 'Auto Express',
    };
  }
  const image = teaserImage ? [teaserImage.src] : [];

  let jsonld = {
    name: altTitle || metaTitle,
    author,
    image: metaImage || image,
    dateModified,
    datePublished,
    metaDescription,
    publisher: {
      '@type': 'Organization',
      name: 'AutoExpress',
      logo: {
        '@type': 'ImageObject',
        url: logoImage,
      },
    },
  };

  if (mappedContentType === 'review') {
    const {
      verdict,
      rating,
    } = pageData;
    jsonld = {
      ...jsonld,
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        bestRating: 5,
        worstRating: 0,
        ratingValue: rating,
      },
      reviewBody: verdict,
      itemReviewed: {
        '@type': ['Product', 'Car'],
        name: product || productFamily || make || metaTitle,
      },
    };
    if (make) {
      jsonld.itemReviewed.brand = {
        '@type': 'Brand',
        name: make,
      };
    }
  } else if (mappedContentType === 'article') {
    jsonld = {
      ...jsonld,
      '@type': 'NewsArticle',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': 'https://google.com/article',
      },
      headline: altTitle || metaTitle,
    };
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      <title>{getPaginatedTitle(metaTitle, page)}</title>
      <meta property="og:type" content="article" />
    </Helmet>
  );
};

getHelmetForContent.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      altTitle: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photo: PropTypes.string,
      })),
      teaserImage: PropTypes.shape({
        id: PropTypes.string,
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        alt: PropTypes.string,
        source: PropTypes.string,
        credit: PropTypes.string,
        caption: PropTypes.string,
      }),
      date: PropTypes.string,
      created: PropTypes.string,
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    ogImage: PropTypes.string,
  }).isRequired,
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string,
    header: PropTypes.shape({
      logoImage: PropTypes.string,
    }),
  }).isRequired,
};

export default getHelmetForContent;

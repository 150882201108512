import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Loading } from '@autovia-uk/polaris-components/components/atoms/Loading';

import { useIsMounted } from 'Helpers/hooks/useIsMounted';
import { useDataFetch } from 'SharedHooks/useDataFetch';
import NewCarDealsQuery from './NewCarDealsQuery.graphql';
import AllBrandsPage from './Page1/AllBrandsPage';
import BrandSelectionPage from './Page2/BrandSelectionPage';
import ModelSelectionPage from './Page3/ModelSelectionPage';
import {
  PAGE_ID_0,
  PAGE_ID_1,
  PAGE_ID_2,
  PAGE_ID_3,
} from './PageConstants';

import {
  allBrandsPageProductData,
  brandSelectionPageProductData,
} from './handleQueryData';

import './_common.scss';

const PAGES = [PAGE_ID_1, PAGE_ID_2, PAGE_ID_3];

export const NewCarDealWidget = ({ content, layout }) => {
  const [pageId, setPageId] = useState(PAGE_ID_0);
  const productDetails = useRef({});
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');

  const isMounted = useIsMounted();

  const { state: productDataFetchState, fetchData } = useDataFetch({ query: NewCarDealsQuery });
  const cssPrefix = 'polaris';

  const setCurrentPage = ({ pageID, productDetails: productDetailsDataNew }) => {
    if (productDetailsDataNew && isMounted()) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(productDetailsDataNew)) {
        // eslint-disable-next-line react/prop-types
        productDetails.current[key] = value;
      }
    }
    if (isMounted()) {
      setPageId(pageID);
    }
    return null;
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setMake(params.get('make'));
    setModel(params.get('model'));

    let storage = window.sessionStorage;
    let pageIdFromStorage = storage.getItem('pageID');
    setPageId(pageIdFromStorage);

    return () => {
      params = null;
      storage = null;
      pageIdFromStorage = null;
    };
  }, []);

  const getProductDetailsData = (variables) => {
    fetchData({ variables }, isMounted);
  };

  useEffect(() => {
    const { sitename } = window.__API_CREDENTIALS__;
    if (model) {
      getProductDetailsData({ sitename, make, model }); // These query variables will change when the backend is updated.
    } else if (make) {
      getProductDetailsData({ sitename, make });
    } else {
      setCurrentPage({ pageID: PAGE_ID_1, productDetails });
    }
  }, [make, model]);

  useEffect(() => {
    const productDetailsNotNull = productDataFetchState.data?.getProductDetails !== null;
    if (model && productDetailsNotNull) {
      brandSelectionPageProductData(productDataFetchState, setCurrentPage, PAGE_ID_3);
    } else if (make && productDetailsNotNull) {
      allBrandsPageProductData(productDataFetchState, setCurrentPage, PAGE_ID_2);
    } else if (!productDetailsNotNull) {
      setCurrentPage({ pageID: PAGE_ID_1, productDetails });
    } else {
      setCurrentPage({ pageID: PAGE_ID_0, productDetails });
    }
  }, [productDataFetchState.isSuccess, productDataFetchState.data]);

  const handleBack = () => {
    const storage = window.sessionStorage;
    const pageIdFromStorage = storage.getItem('pageID');
    const currentPageIndex = PAGES.indexOf(pageIdFromStorage);
    if (currentPageIndex !== 0) {
      setPageId(PAGES[currentPageIndex - 1]);
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBack);
    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, []);

  return (
    <>
      {pageId === PAGE_ID_0 && (
        <div className={`${cssPrefix}__ncd--loading-styles`}>
          <Loading
            loadingText="Finding your next car..."
          />
        </div>
      )}
      {pageId === PAGE_ID_1 && <AllBrandsPage setCurrentPage={setCurrentPage} productDetails={productDetails.current} content={content} layout={layout} /> }
      {pageId === PAGE_ID_2 && <BrandSelectionPage setCurrentPage={setCurrentPage} productDetails={productDetails.current} layout={layout} />}
      {pageId === PAGE_ID_3 && <ModelSelectionPage setCurrentPage={setCurrentPage} productDetails={productDetails.current} layout={layout} />}
    </>
  );
};

NewCarDealWidget.defaultProps = {
  content: [],
  layout: 'default',
};

NewCarDealWidget.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({}),
  })),
  layout: PropTypes.string,
};

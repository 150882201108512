import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'polaris-coreweb/exports';
import { getAuthorsForJsonLd } from '@autovia-uk/polaris-components/sharedHelpers/getAuthorsForJsonLd';
import { getPaginatedTitle } from 'Helpers';

import { getPrimaryMediaPreloadLink } from 'SharedPartialsLocal/getPrimaryMediaPreloadLink';

/**
 * Set Meta tag in the header
 * @returns {*}
 */
const getHelmetForArticle = (props) => {
  const {
    layoutData: {
      page: pageData,
      page: {
        altTitle,
        authors: rawAuthors,
        teaserImage,
        date: dateModified,
        created: datePublished,
        url: dataUrl,
      },
    },
    metaData: {
      pageTitle: metaTitle,
      pageDescription: metaDescription,
      ogImage: metaImage,
    },
    pagination: { page },
    config: {
      siteUrl,
      branding: {
        logoImage: {
          src: logoImageSrc,
          height: logoImageHeight,
          width: logoImageWidth,
        },
      },
      globalSettings,
    },
    context: { imageSizesCallback },
  } = props;

  const authors = getAuthorsForJsonLd({ authors: rawAuthors, siteUrl, siteName: globalSettings && globalSettings.name });
  const image = teaserImage ? [teaserImage.src] : [];

  let jsonld = {
    '@context': 'https://schema.org',
    name: altTitle || metaTitle,
    author: authors,
    image: metaImage || image,
    dateModified,
    datePublished,
    description: metaDescription,
    publisher: {
      '@type': 'Organization',
      name: 'AutoExpress',
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${logoImageSrc}`,
        height: logoImageHeight,
        width: logoImageWidth,
      },
    },
  };

  jsonld = {
    ...jsonld,
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${siteUrl}${dataUrl}`,
    },
    headline: altTitle || metaTitle,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      <title>{getPaginatedTitle(metaTitle, page)}</title>
      <meta property="og:type" content="article" />

      {getPrimaryMediaPreloadLink(pageData, imageSizesCallback)}
    </Helmet>
  );
};

getHelmetForArticle.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      altTitle: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photo: PropTypes.string,
      })),
      teaserImage: PropTypes.shape({
        id: PropTypes.string,
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        alt: PropTypes.string,
        source: PropTypes.string,
        credit: PropTypes.string,
        caption: PropTypes.string,
      }),
      date: PropTypes.string,
      created: PropTypes.string,
      url: PropTypes.string,
      primaryMedia: PropTypes.shape(),
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    ogImage: PropTypes.string,
  }).isRequired,
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string,
    branding: PropTypes.shape({
      logoImage: PropTypes.shape({
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
  }).isRequired,
  context: PropTypes.shape().isRequired,
};

export default getHelmetForArticle;

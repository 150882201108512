import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { getBody } from 'SharedPartialsLocal/getBody';

import { useScrollTop } from 'Helpers/useScrollTop';
import { useDataFetch } from 'SharedHooks/useDataFetch';
import NewCarDealsQuery from '../NewCarDealsQuery.graphql';
import { PAGE_ID_1, PAGE_ID_2, PAGE_ID_3 } from '../PageConstants';
import { brandSelectionPageProductData } from '../handleQueryData';

import './_style.scss';

const BrandSelectionPage = (props) => {
  const {
    context: { cssPrefix },
    productDetails,
    setCurrentPage,
  } = props;

  const sessionStorage = window?.sessionStorage;
  sessionStorage.setItem('pageID', 'PAGE_2_BRAND_SELECTION');

  window.onbeforeunload = () => {
    sessionStorage.clear();
  };

  useScrollTop();

  const logoFromSessionStorage = sessionStorage.getItem(PAGE_ID_2) && JSON.parse(sessionStorage.getItem(PAGE_ID_2)).logo;
  const dataFromSessionStorage = sessionStorage.getItem(PAGE_ID_2) && JSON.parse(sessionStorage.getItem(PAGE_ID_2)).productData;
  const bodyFromSessionStorage = sessionStorage.getItem(PAGE_ID_2) && JSON.parse(sessionStorage.getItem(PAGE_ID_2)).body;

  const contentImage = logoFromSessionStorage || productDetails?.logo;
  const brandSelectionData = dataFromSessionStorage || productDetails?.productData?.data;
  const bodyContent = bodyFromSessionStorage || productDetails?.body;

  const bodyBlocks = getBody({ bodyInjected: bodyContent });

  const { state: productDataFetchState, fetchData } = useDataFetch({ query: NewCarDealsQuery });

  useEffect(() => {
    brandSelectionPageProductData(productDataFetchState, setCurrentPage, PAGE_ID_3);
  }, [productDataFetchState.isSuccess, productDataFetchState.data]);

  const useBrowserBackButton = () => {
    const [isBackButtonClicked, setBackButtonClicked] = useState(false);

    const onBackButtonEvent = (e) => {
      e.preventDefault();

      if (!isBackButtonClicked) {
        setBackButtonClicked(true);
        setCurrentPage(PAGE_ID_1);
        window.sessionStorage.setItem('pageID', 'PAGE_1_ALL_BRANDS');
      } else {
        window.history.pushState(null, null, window.location.href);
        setBackButtonClicked(false);
      }
    };

    useEffect(() => {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }, []);
  };


  useBrowserBackButton(setCurrentPage);

  const getProductDetailsData = (productId) => {
    const { sitename } = window.__API_CREDENTIALS__;
    const variables = { sitename, id: productId };
    fetchData({ variables });
  };

  const handleClick = (productId, model) => {
    ReactGA.event({
      category: 'Car Discovery Journey',
      action: 'Model Selection Click',
      label: model,
    });
    window.sessionStorage.setItem('model', model);
    getProductDetailsData(productId);
  };

  return (
    <div className={`${cssPrefix}__newCarDeals--page-2`}>
      <div className={`${cssPrefix}__brand-header-container`}>
        {productDetails && productDetails.name
        && (
          <p className={`${cssPrefix}__brand-header-text`}>
            {`Which ${productDetails.name} suits you best?`}
          </p>
        )
        }
        {productDetails && productDetails?.logo
        && (
          <Image
            extraClassNames={{ [`${cssPrefix}__brand-image`]: true }}
            size="sponsor"
            src={contentImage.src}
          />
        )
        }
      </div>
      {productDetails && productDetails.name
        && (
          <div className={`${cssPrefix}__subtitle-container`}>
            <p>
              {`Get more information on the latest ${productDetails.name} range. Download a brochure, register your interest or arrange a test drive…`}
            </p>
          </div>
        )
      }
      <div className={`${cssPrefix}__brand-body-container`}>{bodyBlocks}</div>
      <div className={`${cssPrefix}__brand-selection-content`}>
        {brandSelectionData && brandSelectionData.map((brand, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
          <div
            className={`${cssPrefix}__brand-selection-link`}
            key={`brand-selection-link-${index}`}
            onClick={() => handleClick(brand.id, brand.name)}
            role="button"
            data-id={brand.id}
          >
            <div className={`${cssPrefix}__brand-selection-image-container`}>
              {brand?.primaryImage && (
              <Image
                {...brand.primaryImage}
                extraClassNames={{ [`${cssPrefix}__brand-selection-image`]: true }}
                size="primary-image"
                src={brand.primaryImage.src}
              />
              )}
            </div>
            <div className={`${cssPrefix}__brand-selection-cta-container`}>
              <span className={`${cssPrefix}__brand-selection-title`}>{brand.fullName}</span>
              <Button
                type="submit"
                label="more info"
                srLabel="more info"
                variant=""
                extraClassNames={{ [`${cssPrefix}__brand-selection-button`]: true }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

BrandSelectionPage.defaultProps = {
  context: {},
  productDetails: [],
};

BrandSelectionPage.propTypes = {
  context: PropTypes.shape(),
  productDetails: PropTypes.shape(),
  setCurrentPage: PropTypes.func.isRequired,
};

export default withPolaris(BrandSelectionPage);

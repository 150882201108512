import React from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import ReactGA from 'react-ga';

const DesktopModelSelectionPage = (props) => {
  const {
    context: { cssPrefix },
    bodyBlocks,
    brandDetails,
    primaryMedia,
    productDetails,
    vehicleNextSteps,
  } = props;

  const readMoreUrlValue = productDetails?.readMoreUrl;

  const handleClick = (e) => {
    ReactGA.event({
      category: 'Car Discovery Journey',
      action: 'Click ‘Read More’',
      label: 'Read More',
    });
    return true;
  };

  return (
    <div className={`${cssPrefix}__container_section`}>
      <div className={`${cssPrefix}__description-section`}>
        <div className={`${cssPrefix}__model-header-container`}>
          {brandDetails}
        </div>
        <div>
          {bodyBlocks}
          {readMoreUrlValue && (
            <Link
              extraClassNames={{ [`${cssPrefix}__model-selection-readmore-cta`]: true }}
              href={readMoreUrlValue}
              onClick={handleClick}
            >
              Read more
            </Link>
          )}
        </div>
      </div>
      <div className={`${cssPrefix}__slider-section`}>
        <div className={`${cssPrefix}__model-selection-content`}>
          {primaryMedia}
        </div>
        {vehicleNextSteps}
      </div>
    </div>
  );
};

DesktopModelSelectionPage.defaultProps = {
  context: {},
  bodyBlocks: null,
  brandDetails: null,
  primaryMedia: null,
  productDetails: [],
  vehicleNextSteps: null,
};

DesktopModelSelectionPage.propTypes = {
  context: PropTypes.shape(),
  bodyBlocks: PropTypes.oneOfType([PropTypes.array, PropTypes.shape()]),
  brandDetails: PropTypes.node,
  primaryMedia: PropTypes.node,
  productDetails: PropTypes.shape(),
  vehicleNextSteps: PropTypes.node,
};

export default withPolaris(DesktopModelSelectionPage);

/**
 * Get the layout configuration based on the layout type.
 *
 * @param {string} layout - The type of layout.
 * @returns {Object} The layout configuration.
 */
export function useLayout(layout) {
  const baseLayout = {
    bodyLandingPageClass: false,
    fullwidth: false,
    hideBreadcrumbs: false,
    hideSubtitle: false,
    hideSidebar: false,
    hideMostPopular: false,
    showTitle: false,
    hideBelowHeaderAd: false,
    hidePageImpressionAd: false,
    hideOOPsAds: false,
    hideStickyFooterAd: false,
    useReducedFooter: false,
    headerLandingPageClass: false,
    hideHeaderNavigation: false,
    hideProductSelector: false,
  };

  switch (layout) {
    case 'Full Width':
    case 'fullWidth':
      return {
        ...baseLayout,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        showTitle: true,
        hideBelowHeaderAd: true,
        hideStickyFooterAd: true,
      };
    case 'Landing Page':
    case 'landingPage':
      return {
        ...baseLayout,
        bodyLandingPageClass: true,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        hideBelowHeaderAd: true,
        hidePageImpressionAd: true,
        hideOOPsAds: true,
        hideStickyFooterAd: true,
        useReducedFooter: true,
        headerLandingPageClass: true,
        hideHeaderNavigation: true,
        hideProductSelector: true,
      };
    case 'plainFullWidth':
      return {
        ...baseLayout,
        bodyLandingPageClass: true,
        fullwidth: true,
        hideBreadcrumbs: true,
        hideSubtitle: true,
        hideSidebar: true,
        hideMostPopular: true,
        hideBelowHeaderAd: true,
        hideStickyFooterAd: true,
        hideProductSelector: true,
      };
    default:
      return {
        ...baseLayout,
      };
  }
}

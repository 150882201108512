import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDisableBodyScroll } from 'Helpers/useDisableBodyScroll';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import './_style.scss';

const Popup = ({
  context: { cssPrefix },
  isHideModal,
  onCloseModal,
  children,
  isHideCloseAction,
}) => {
  const closePopupRef = useRef(null);
  const handleClickOutside = (e) => {
    if (!closePopupRef.current?.contains(e.target)) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useDisableBodyScroll(!isHideModal);

  return (
    <div
      className={classNames({
        [`${cssPrefix}__modal-container`]: true,
        [`${cssPrefix}__visible`]: isHideModal,
      })}
    >
      <div ref={closePopupRef} className={`${cssPrefix}__modal-content`}>
        <button
          type="button"
          onClick={onCloseModal}
          className={classNames({
            [`${cssPrefix}__modal-close`]: true,
            [`${cssPrefix}__modal-close-visible`]: isHideCloseAction,
          })}
        />
        {children}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  context: {},
  onCloseModal: () => {},
  isHideModal: true,
  isHideCloseAction: false,
};

Popup.propTypes = {
  context: PropTypes.shape(),
  onCloseModal: PropTypes.func,
  isHideModal: PropTypes.bool,
  isHideCloseAction: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default withPolaris(Popup);

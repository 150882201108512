import React from 'react';
import PropTypes from 'prop-types';

import {
  getMappedContentType,
} from 'Helpers';

import {
  getHelmetForArticle,
  getHelmetForAuthor,
  getHelmetForContent,
  getHelmetForErrorPage,
  getHelmetForGallery,
  getHelmetForHome,
  getHelmetForIndexTemplate,
  getHelmetForLoading,
  getHelmetForNotFound,
  getHelmetForPage,
  getHelmetForReview,
  getHelmetForSearch,
  getHelmetForSpec,
} from './getHelmet';

const getHelmetForTemplate = (templateName) => {
  const helmets = {
    article: getHelmetForArticle,
    author: getHelmetForAuthor,
    content: getHelmetForContent,
    error: getHelmetForErrorPage,
    gallery: getHelmetForGallery,
    home: getHelmetForHome,
    index: getHelmetForIndexTemplate,
    loading: getHelmetForLoading,
    notFound: getHelmetForNotFound,
    page: getHelmetForPage,
    review: getHelmetForReview,
    search: getHelmetForSearch,
    spec: getHelmetForSpec,
  };

  return helmets[templateName];
};

export const withHelmet = templateName => (BaseComponent) => {
  const BaseComponentWithHelmet = (props) => {
    const Helmet = getHelmetForTemplate(templateName);
    return (
      <>
        <Helmet {...props} />
        <BaseComponent {...props} />
      </>
    );
  };

  withHelmet.propTypes = {
    config: PropTypes.shape({}).isRequired,
    layoutData: PropTypes.shape({
      page: PropTypes.shape({
        author: PropTypes.shape({
          name: PropTypes.string,
          bio: PropTypes.string,
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          photo: PropTypes.string,
        }),
      }).isRequired,
      pagination: PropTypes.shape({
        isLoading: PropTypes.bool,
        loadMore: PropTypes.func,
        page: PropTypes.number,
        pages: PropTypes.objectOf(),
        setPageRef: PropTypes.func,
        hasMore: PropTypes.bool,
      }),
    }).isRequired,
  };
  withHelmet.defaultProps = {
    pagination: {
      isLoading: false,
      loadMore: () => null,
      page: 0,
      pages: 0,
      setPageRef: () => null,
      hasMore: false,
    },
  };
  withHelmet.displayName = 'withHelmet()';

  return BaseComponentWithHelmet;
};

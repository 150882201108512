import React from 'react';
import { getRelatedContent } from 'Helpers';
import { Pagination } from '@autovia-uk/polaris-components/components/molecules/Pagination';

export const getPagination = (associated, currUrl) => {
  const config = {
    prev: {
      url: null,
      subtitle: null,
    },
    next: {
      url: null,
      subtitle: null,
    },
  };

  const relatedPages = getRelatedContent(associated, 'relatedPages');
  if (Array.isArray(relatedPages) && relatedPages.length) {
    const currItemIndex = relatedPages.map(relatedPage => relatedPage.link).indexOf(currUrl);

    if (currItemIndex > 0) {
      config.prev.url = relatedPages[currItemIndex - 1].link;
      config.prev.subtitle = relatedPages[currItemIndex - 1].title;
    }

    if (currItemIndex < (relatedPages.length - 1)) {
      config.next.url = relatedPages[currItemIndex + 1].link;
      config.next.subtitle = relatedPages[currItemIndex + 1].title;
    }
  } else {
    return false;
  }

  return (
    <Pagination
      previousLabel="Go Back to"
      previousTitle={config.prev.subtitle}
      previousUrl={config.prev.url}
      previousIcon="prev"
      nextLabel="Continue Reading"
      nextTitle={config.next.subtitle}
      nextUrl={config.next.url}
      nextIcon="next"
    />
  );
};

import React from 'react';

// TODO extract this to coreweb or CL
export const helmetScripts = embedTypes => Array.from(embedTypes).map((type) => {
  if (type === 'Twitter') {
    return (
      <>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          key={type}
        />
        <meta
          charset="utf-8"
        />
      </>
    );
  }
  if (type === 'Facebook') {
    return (
      <script
        async="1"
        defer="1"
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v3.3"
        key={type}
      />
    );
  }
  if (type === 'Instagram') {
    return (
      <script
        async="1"
        defer="1"
        src="https://www.instagram.com/embed.js"
        key={type}
      />
    );
  }
  return false;
}).filter(Boolean);

/* eslint-disable react/prop-types */
import React from 'react';
import { NextPages } from '@autovia-uk/polaris-components/components/molecules/NextPages';

export const getNextPages = ({ pages, currentUrl, title }) => {
  if (Array.isArray(pages) && pages.length > 0) {
    return (
      <NextPages
        title={title}
        currentPageUrl={currentUrl}
        content={pages.map((item) => {
          const { subtitle: removedSubtitle, ...noSubtitle } = item;
          return noSubtitle;
        })}
      />
    );
  }

  return null;
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

const withViewport = (Component) => {
  const WithViewPort = (props) => {
    const {
      config: {
        breakpoints,
      },
    } = props;

    const { isMobile, isDesktop } = useViewportType({
      breakpointsMobile: breakpoints.mobile,
      breakpointsDesktop: breakpoints.desktop,
    });

    const MemoizedComponent = useMemo(() => (
      <Component isMobile={isMobile} isDesktop={isDesktop} {...props} />
    ), [isMobile, isDesktop, props]);

    return MemoizedComponent;
  };

  WithViewPort.propTypes = {
    config: PropTypes.shape({
      breakpoints: PropTypes.shape({
        desktop: PropTypes.arrayOf(PropTypes.shape({
          min: PropTypes.number.isRequired,
          max: PropTypes.number.isRequired,
        })),
        mobile: PropTypes.arrayOf(PropTypes.shape({
          min: PropTypes.number.isRequired,
          max: PropTypes.number.isRequired,
        })),
      }).isRequired,
    }),
  };

  return WithViewPort;
};

export default withViewport;

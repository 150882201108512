import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDisableBodyScroll } from 'Helpers/useDisableBodyScroll';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';

import './_style.scss';

const ModalPopup = ({
  context: { cssPrefix },
  isHideModal,
  onCloseModal,
  children,
}) => {
  const closePopupRef = useRef(null);
  const handleClickOutside = (e) => {
    if (!closePopupRef.current?.contains(e.target)) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useDisableBodyScroll(!isHideModal);

  return (
    <div
      className={classNames({
        [`${cssPrefix}__faq-modal-container`]: true,
        [`${cssPrefix}__visible`]: isHideModal,
      })}
    >
      <div ref={closePopupRef} className={`${cssPrefix}__faq-modal-content`}>
        <div className={`${cssPrefix}__modal-button`}>
          <Image
            onClick={onCloseModal}
            src="/public/close-modal.svg"
            alt="close"
            lazyLoad
          />
        </div>
        {children}
      </div>
    </div>
  );
};

ModalPopup.defaultProps = {
  context: {},
  onCloseModal: () => {},
  isHideModal: true,
};

ModalPopup.propTypes = {
  context: PropTypes.shape(),
  onCloseModal: PropTypes.func,
  isHideModal: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default withPolaris(ModalPopup);

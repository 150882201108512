/**
 * @render react
 * @name Header
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useLayout } from 'Helpers/hooks/useLayout';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation'; // Button, NavigationItem (Icon and Link)
import { ProductSelector } from '@autovia-uk/polaris-components/components/molecules/ProductSelector';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch'; // Button, Input, Form, Label

import './_style.scss';

const Header = (props) => {
  const {
    context: {
      cssPrefix,
      header: {
        navigation,
      },
      branding: {
        logoImage,
      },
    },
    config: {
      login: {
        accountMenu,
      },
      productSelector: {
        popularInputs,
        popularInputsLabel,
      },
    },
    isHomeTemplate,
    layoutType,
  } = props;
  const searchRef = React.createRef();
  const [isOpen, setOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const {
    headerLandingPageClass,
    hideHeaderNavigation,
    hideProductSelector,
  } = useLayout(layoutType);

  const onClick = () => {
    setOpen(!isOpen);
    document.documentElement.classList.toggle('-menu-open');
  };

  const onSearchClick = () => {
    setSearchOpen(!isSearchOpen);
    setOpen(false);
    document.documentElement.classList.toggle('-search-open');
    document.documentElement.classList.remove('-menu-open');
  };

  const onSearchSubmit = () => {
    onSearchClick();
  };

  /**
   * Pushes tracking data to the dataLayer array.
   *
   * @param {Object} trackingData - The tracking data to be pushed to the dataLayer.
   */
  const onPromoBlockLinkClick = (trackingData) => {
    window.dataLayer = window.dataLayer || [];
    if (trackingData) {
      window.dataLayer.push(trackingData);
    }
  };

  const TRACKING_DATA = {
    GYC: {
      event: 'marketplace.button_click',
      'event.type': 'GYC',
      'marketplace_button.placement': 'GYCHeaderPromoBlock',
    },
    SMC: {
      event: 'marketplace.button_click',
      'event.type': 'SMC',
      'marketplace_button.placement': 'SMCHeaderPromoBlock',
    },
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && isSearchOpen) {
      if (searchRef && searchRef.current) {
        const inputEl = searchRef.current.querySelector('input[type="search"]');
        setTimeout(() => {
          inputEl.focus();
        }, 0);
      }
    }
  }, [isSearchOpen]);

  // On login click, check if cookie signed_in is false and redirect to login page
  const onLogInClick = () => {
    if (!isLoggedIn) {
      window.location.href = 'https://marketplace.autoexpress.co.uk/users/sign_in';
    }
  };

  const onLoggedInClick = () => {
    setAccountOpen(!isAccountOpen);
    document.documentElement.classList.toggle('-account-menu-open');
  };

  // If cookie signed_in is true, set isLoggedIn to true
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const signedIn = document.cookie.split(';').find(row => row.trim().startsWith('signed_in='));
      setIsLoggedIn(signedIn && signedIn.split('=')[1] === '1');

      // Track if the user is logged in
      window.dataLayer.push(
        {
          event: 'logged-in',
          LoggedIn: (signedIn && signedIn.split('=')[1] === '1') || false,
        },
      );
    }
  }, []);

  // remove empty objects from navigation
  const filteredNavigation = navigation && navigation.filter(value => Object.keys(value).length !== 0);

  return (
    <>
      <div className="screen-reader-text">
        <a href="#main">Skip to Content</a>
        <a href="#footer">Skip to Footer</a>
      </div>
      <header
        className={classNames({
          [`${cssPrefix}__header`]: true,
          [`${cssPrefix}__header-landing`]: headerLandingPageClass,
        })}
        id="header"
      >
        <div
          className={classNames({
            [`${cssPrefix}__header--inner`]: true,
            '-is-menu-open': isOpen,
            '-is-search-open': isSearchOpen,
          })}
        >
          <div
            className={classNames({
              [`${cssPrefix}__header--logo`]: true,
            })}
          >
            <Link href="/">
              <Image
                src={logoImage.src}
                lazyLoad={false}
              />
            </Link>
          </div>

          {!hideHeaderNavigation && (
            <div
              className={classNames({
                [`${cssPrefix}__header--navigation-search`]: true,
              })}
            >
              <div
                className={classNames({
                  [`${cssPrefix}__header--search`]: true,
                })}
                ref={searchRef}
              >
                <SimpleSearch
                  label=""
                  placeholder="start typing to search"
                  url="/search"
                  submitLabel=""
                  closeOnClick={onSearchClick}
                  onSubmit={onSearchSubmit}
                  responsive
                />
                <Button
                  type="button"
                  label="Search"
                  srLabel="Search"
                  variant=""
                  onClick={onSearchClick}
                  extraClassNames={{
                    [`${cssPrefix}__header--search-button`]: true,
                  }}
                />
              </div>

              <div
                className={classNames({
                  [`${cssPrefix}__header--login`]: true,
                })}
              >
                {isLoggedIn ? (
                  <>
                    <Navigation
                      id="polaris__account-menu"
                      content={accountMenu}
                      onButtonClick={onLoggedInClick}
                      navigationClassName={`${cssPrefix}__header--navigation`}
                      buttonLabel={isAccountOpen ? 'Close' : 'Account'}
                      buttonClassName={`${cssPrefix}__header--menu-button`}
                      subMenuIcon={`${cssPrefix}__ico-down`}
                      responsive
                      responsiveNavHeading="Account"
                      activeWildcard={false}
                      subMenuClickBreakpoint="tablet"
                    />
                  </>
                ) : (
                  <Button
                    type="button"
                    label="Log in"
                    srLabel="Log in"
                    variant=""
                    onClick={onLogInClick}
                    extraClassNames={{
                      [`${cssPrefix}__header--login-button`]: true,
                    }}
                  />
                )}
              </div>

              {filteredNavigation && (
                <Navigation
                  id="polaris__top-menu"
                  content={filteredNavigation}
                  onButtonClick={onClick}
                  navigationClassName={`${cssPrefix}__header--navigation`}
                  buttonLabel={isOpen ? 'Close' : 'Menu'}
                  buttonClassName={`${cssPrefix}__header--menu-button`}
                  subMenuIcon={`${cssPrefix}__ico-down`}
                  responsive
                  responsiveNavHeading="Menu"
                  activeWildcard={false}
                  subMenuClickBreakpoint="tablet"
                />
              )}
            </div>
          )}
        </div>
      </header>

      {isHomeTemplate && (
        <div className={`${cssPrefix}__header--promo-block`}>
          <Link
            href="/buy-a-car"
            extraClassNames={{
              [`${cssPrefix}__header--promo-block--gyc`]: true,
            }}
            onClick={() => onPromoBlockLinkClick(TRACKING_DATA.GYC)}
          >
            Find a car
          </Link>
          <Link
            href="/car-valuation"
            extraClassNames={{
              [`${cssPrefix}__header--promo-block--smc`]: true,
            }}
            onClick={() => onPromoBlockLinkClick(TRACKING_DATA.SMC)}
          >
            Value my car
          </Link>
        </div>
      )}
      {!hideProductSelector && (
        <div className={`${cssPrefix}__header--product-selector--wrapper`}>
          <ProductSelector
            extraClassNames={{ [`${cssPrefix}__header--product-selector`]: true }}
            groupOptions
            popularInputs={popularInputs}
            popularInputsLabel={popularInputsLabel}
          />
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  context: PropTypes.shape().isRequired,
  config: PropTypes.shape().isRequired,
  isHomeTemplate: PropTypes.bool,
  layoutType: PropTypes.string,
};

Header.defaultProps = {
  isHomeTemplate: false,
  layoutType: '',
};

export default withPolaris(Header);

import { compose } from 'recompose';

import {
  withHelmet,
  withTemplate,
} from 'Protons';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import Home from './Home';

const ComposedHome = compose(
  withTemplate,
  withPolaris,
  withHelmet('home'),
)(Home);

export default ComposedHome;

import React from 'react';
import PropTypes from 'prop-types';

import { HtmlSafe } from 'polaris-coreweb/exports';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { CarwowValuationWidget } from '@autovia-uk/polaris-components/components/atoms/CarwowValuationWidget';

import './_style.scss';

const SellYourCarBanner = (
  props,
) => {
  const {
    context: {
      cssPrefix,
    },
    impactUrl,
    title,
    desc,
  } = props;

  return (
    <>
      <div className={`${cssPrefix}__sell-my-car-banner-main`}>
        <div className={`${cssPrefix}__sell-my-car-banner-content`}>
          <h1>{title}</h1>
          <p><HtmlSafe html={desc} /></p>
        </div>
        <div className={`${cssPrefix}__sell-my-car-banner-valuation-tool`}>
          <CarwowValuationWidget impactUrl={impactUrl} key="VALUATION_WIDGET" />
        </div>
      </div>
    </>
  );
};

SellYourCarBanner.propTypes = {
  context: PropTypes.shape(),
  impactUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

SellYourCarBanner.defaultProps = {
  context: {},
  impactUrl: '',
};

export default withPolaris(SellYourCarBanner);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';

import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';

import './_style.scss';

const HowDoISellMyCar = (
  props,
) => {
  const [showDetail, setShowDetail] = useState(false);
  const {
    context: {
      cssPrefix,
    },
    list,
    title,
  } = props;

  const handleToggle = () => {
    setShowDetail(!showDetail);
  };

  return (
    <div className={`${cssPrefix}__howDoISellMyCar`}>
      <h3>{title}</h3>
      <div className={`${cssPrefix}__grid -with-columns-4`}>
        {list && list.map((item, index) => (
          <div className={`${cssPrefix}__howDoISellMyCar-column`} key={`howDoISellMyCar_${index}`}>
            <div className={`${cssPrefix}__howDoISellMyCar-line-horizontal ${cssPrefix}__howDoISellMyCar-arrow-right`} />
            <h5>
              <span className={`${cssPrefix}__howDoISellMyCar-number`}>
                {index + 1}
                .
                {' '}
              </span>
              {item.title}
            </h5>
            {showDetail && (<p>{item.desc}</p>)}
          </div>
        ))}
      </div>
      <div className={`${cssPrefix}__grid -with-columns-1 ${cssPrefix}__sell-my-car-action`}>
        <div className={`${cssPrefix}__sell-my-car-action-button`}>
          <Image
            extraClassNames={{
              [`${cssPrefix}__action-img`]: true,
            }}
            onClick={handleToggle}
            src={!showDetail ? '/public/Open.svg' : '/public/Close.svg'}
            alt=""
            lazyLoad
          />
        </div>
      </div>
    </div>
  );
};

HowDoISellMyCar.propTypes = {
  context: PropTypes.shape(),
  list: PropTypes.arrayOf(PropTypes.shape({
    desc: PropTypes.string,
    title: PropTypes.string,
  })),
  title: PropTypes.string,
};

HowDoISellMyCar.defaultProps = {
  context: {},
  list: {},
  title: '',
};

export default withPolaris(HowDoISellMyCar);

export const allBrandsPageProductData = (productDataFetchState, setCurrentPage, pageId) => {
  if (productDataFetchState.isSuccess) {
    const hasError = productDataFetchState.data?.getProductDetails?.error;
    if (!hasError) {
      const {
        data: {
          getProductDetails: {
            logo,
            subHeading,
            body,
            name,
            productData: {
              ...data
            },
          },
        },
      } = productDataFetchState;

      sessionStorage.setItem(pageId, JSON.stringify({
        logo,
        subHeading,
        body,
        name,
        productData: data.data,
      }));

      setCurrentPage({
        pageID: pageId,
        productDetails: {
          logo,
          subHeading,
          name,
          body,
          productData: {
            ...data,
          },
        },
      });
    }
  }
};

export const brandSelectionPageProductData = (productDataFetchState, setCurrentPage, pageId) => {
  if (productDataFetchState.isSuccess) {
    const hasError = productDataFetchState.data?.getProductDetails?.error;

    if (!hasError) {
      const {
        data: {
          getProductDetails: {
            vehicleNextSteps,
            logo,
            subHeading,
            body,
            primaryMedia,
            productData,
            readMoreUrl,
          },
        },
      } = productDataFetchState;

      const brochure = vehicleNextSteps?.brochure || null;
      const dealer = vehicleNextSteps?.dealer || null;
      const testDrive = vehicleNextSteps?.testDrive || null;

      sessionStorage.setItem(pageId, JSON.stringify({
        vehicleNextSteps,
        brochure,
        dealer,
        testDrive,
        logo,
        subHeading,
        body,
        primaryMedia,
        productData: productData?.data,
        readMoreUrl,
      }));

      setCurrentPage({
        pageID: pageId,
        productDetails: {
          vehicleNextSteps: {
            brochure,
            dealer,
            testDrive,
          },
          logo,
          subHeading,
          body,
          primaryMedia,
          productData,
          readMoreUrl,
        },
      });
    }
  }
};

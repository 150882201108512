import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Popup } from 'Organisms/Popup';

import './_style.scss';

const BannerEvent = ({
  cssPrefix,
}) => {
  const [modalStatus, setModalStatus] = useState(true);
  const [config, setConfig] = useState();
  const localCssPrefix = `${cssPrefix}__bannerEvent`;
  useEffect(() => {
    const handleBannerEvent = (newConfig) => {
      if (newConfig) {
        setConfig((state => ({ ...state, ...newConfig })));
        setModalStatus(false);
      }
    };
    if (typeof window !== 'undefined') {
      window.__HANDLE_BANNER_EVENT = handleBannerEvent;
    }
    const scriptTag = document.createElement('script');
    if (config) {
      scriptTag.id = config.script.id;
      scriptTag.type = 'text/javascript';
      scriptTag.src = config.script.src;
      scriptTag.async = true;
      document.head.appendChild(scriptTag);
    }
    return () => {
      if (config) {
        document.head.removeChild(scriptTag);
      }
    };
  }, [modalStatus, config]);

  const handleClose = () => {
    setModalStatus(true);
  };
  return (modalStatus === false && config) ? (
    <Popup isHideModal={modalStatus} onCloseModal={handleClose}>
      <div className={`${localCssPrefix}-container`}>
        {
          config && (
            <>
              <div className={`${localCssPrefix}-title`}>
                <Heading size={3}>{config.content.title}</Heading>
              </div>
              <div className={`${localCssPrefix}-desc`}>
                {config.content.desc}
              </div>
              <iframe title={config.content.title} frameBorder="0" className={`${localCssPrefix}-iframe`} src={config.content.formUrl} />
            </>
          )
        }
      </div>
    </Popup>
  ) : <></>;
};

BannerEvent.propTypes = {
  cssPrefix: PropTypes.string,
};
BannerEvent.defaultProps = {
  cssPrefix: '',
};
export default BannerEvent;

import { formatURLforPPC } from 'SharedHelpers/formatURLforPPC';

export const getPromoBoxSettings = ({
  promoBoxSettings,
  extraClassNames,
  ctaUrl,
  mainTitle,
  image,
  ppc,
}) => {
  const clonedCtaUrl = ctaUrl || promoBoxSettings.ctaUrl;

  return ({
    ...promoBoxSettings,
    extraClassNames,
    mainTitle: mainTitle || promoBoxSettings.mainTitle,
    image: image || promoBoxSettings.image,
    ctaUrl: clonedCtaUrl && formatURLforPPC({
      url: clonedCtaUrl,
      ppc,
    }),
  });
};

import React from 'react';
import { BreadcrumbWrapper } from 'Protons/BreadcrumbWrapper';

export const getBreadcrumbs = (
  breadcrumbs = [],
  isSponsored = false,
  kicker = '',
) => (
  <BreadcrumbWrapper
    content={breadcrumbs}
    kicker={kicker}
    type={isSponsored ? 'sponsored' : 'default'}
  />
);

/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import get from 'lodash.get';

import {
  Helmet,
  useBlueConic,
  useCrimtan,
  useOneSignal,
  usePermutive,
} from 'polaris-coreweb/exports';

import {
  getCustomizedAdConfig,
  getMappedContentType,
  getMappedLayoutType,
} from 'Helpers';

import { pushWebVitalsToDataLayer } from 'SharedPartials/pushWebVitalsToDataLayer';
import { setPageTargeting } from '@autovia-uk/polaris-components/sharedHelpers/googlePublisherTag';

import { PolarisApp, withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { withMeta } from 'Protons/withMeta';
import { withViewport } from 'Protons';

import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Footer, Header } from 'Organisms';
import { useLayout } from 'Helpers/hooks/useLayout';

import { ProductSelectorQuery } from './ProductSelectorQuery.graphql';

import { contextOverride } from './contextOverride';

/**
 * Ad: Out of page
 * @returns {*}
 * Returns four Out of Page Ad Components
 */
const getOOPAds = () => {
  const oops = ['oop_1', 'oop_2', 'oop_3', 'oop_4'];

  return oops.map(element => (
    <Ad
      id={element}
      key={element}
      outOfPage
      targeting={{
        position: element,
        placement: element,
        refresh: 'no',
      }}
    />
  ));
};

/**
 * HOC to wrap templates to reduce boilerplate code.
 * @param {Component} Template The React component to wrap.
 */
const withTemplate = (Template) => {
  // Creates component with PolarisApp with correct image callback.
  const WrappedTemplate = (props) => {
    const {
      adConfig: pageConfig = {},
      client,
      config = {},
      config: {
        branding: {
          logoImage,
        },
        globalSettings: {
          adSettings: {
            afterNavigationDesktopAds,
            afterNavigationMobileAds,
          },
          blueconicScriptUrl,
          disableBrowserRouter,
          gptTracking,
          headerBidding: {
            amazonHeaderBiddingEnabled,
            amazonPubId,
          },
          permutive: {
            projectId,
            publicApiKey,
            namespace,
          },
        },
        oneSignalAppId,
      },
      dataLayer,
      layoutData,
      metaData,
    } = props;
    /**
     * Spread Operator madness! https://codeburst.io/use-es2015-object-rest-operator-to-omit-properties-38a3ecffe90
     *   combines pageConfig and config.ads into new adConfig object
     *   strips out (global) targeting from adConfig to be fired separately from individual ads
     *   moves targeting to new adConfig.globalTargeting object (in case needed again)
     */

    // Initialize adConfig object with page and template ad configurations
    const adConfig = {
      ...pageConfig,
      templates: config.ads || {},
      globalTargeting: pageConfig.targeting,
    };

    // Extract targeting parameters from adConfig.
    let { targeting } = adConfig;

    // Get the Blueconic targeting parameters from local storage.
    if (typeof Storage !== 'undefined') {
      const targetingParamStr = localStorage.getItem('bcDFPTargetingParams');
      if (targetingParamStr) {
        try {
          const targetingParameters = JSON.parse(targetingParamStr);
          if (Array.isArray(targetingParameters)) {
            targeting = targetingParameters.reduce((acc, { key, value }) => {
              acc[key] = value;
              return acc;
            }, targeting);
          }
        } catch {
          // Do nothing for now.
        }
      }
    }

    setPageTargeting(targeting);
    const mappedContentType = getMappedContentType(props);
    const isHomeTemplate = mappedContentType === 'home';

    const layoutType = layoutData?.page?.layoutType;
    const fullWidth = props?.layoutData?.page?.fullWidth ?? false;
    const layout = fullWidth ? 'fullWidth' : layoutType;
    const mappedLayoutType = getMappedLayoutType(props);
    const isCommercialPage = mappedLayoutType === 'commercialPage';
    const isCommercialPageTitleImage = mappedLayoutType === 'commercialPageTitleImage';

    const {
      hideBelowHeaderAd,
      hidePageImpressionAd,
      hideOOPsAds,
    } = useLayout(layout);

    const { loading: makeModelDataLoading, data: makeModelData } = useQuery(ProductSelectorQuery, {
      ssr: false,
      client,
    });

    const BelowHeaderAd = () => {
      if (isHomeTemplate) return null;
      if (isCommercialPage) return null;
      if (isCommercialPageTitleImage) return null;
      if (hideBelowHeaderAd) return null;

      return (
        <div
          className={classNames({
            'polaris__below-header-ad-wrapper': true,
            '-after-nav-mobile-ad': afterNavigationMobileAds,
            '-after-nav-desktop-ad': afterNavigationDesktopAds,
          })}
        >
          <Ad
            {...getCustomizedAdConfig({
              desktopRule: afterNavigationDesktopAds,
              mobileRule: afterNavigationMobileAds,
            })}
            extraClassNames={{
              '-full-width': true,
              '-below-header': true,
              'hide-mobile': !afterNavigationMobileAds,
              'hide-tablet': !afterNavigationMobileAds,
              'hide-desktop': !afterNavigationDesktopAds,
            }}
            id="refresh-below_header"
            isNoBackground
            isSkippable
            isSpaceReserved
            targeting={{
              position: 'below_header',
              placement: 'below_header',
              refresh: 'yes',
            }}
          />
        </div>

      );
    };

    useEffect(() => {
      if (!amazonHeaderBiddingEnabled || !amazonPubId) return;

      window.apstag.init({
        pubID: amazonPubId,
        adServer: 'googletag',
        simplerGPT: true,
      });
    }, []);

    if (gptTracking && gptTracking === true) {
      if (typeof window !== 'undefined') {
        import('gpt-tracker')
          .then((tracker) => {
            if (window.ga) {
              tracker.init();
            }
          });
      }
    }

    pushWebVitalsToDataLayer();

    useBlueConic(blueconicScriptUrl);
    useCrimtan();
    usePermutive(
      projectId,
      publicApiKey,
      dataLayer,
      namespace,
      config,
    );

    // OneSignal
    useOneSignal(oneSignalAppId);

    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            href="/fonts/fonts.css"
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
          />
          <noscript dangerouslySetInnerHTML={{
            __html: `
              <link
                rel="stylesheet"
                href="/fonts/fonts.css"
              />
              `,
          }}
          />

          <link rel="apple-touch-icon" href={logoImage?.src} />

          {
            isHomeTemplate && (
              // eslint-disable-next-line react/no-unknown-property
              <script type="application/ld+json" root="true">
                {JSON.stringify({
                  '@type': 'WebSite',
                })}
              </script>
            )
          }
        </Helmet>
        {/* TODO: we need to use the withHelmet in the compose function here - you need to use getMappedContentType in the withHelmer, look at the props and get the mapped name; then you can remove them from the templates compose function */}
        {/* Can we use useContext and overwrite the CL context here? */}
        <PolarisApp
          config={{
            ...contextOverride(config, adConfig),
            makeModelData: makeModelDataLoading
              ? get(config, 'globalSettings.productData', [])
              : get(makeModelData, 'getSettings.productData', []),
            metaData,
            disableBrowserRouter,
            dataLayer,
          }}
        >
          <div className={`polaris__app polaris__${getMappedContentType(props)}--template`}>
            {(!hidePageImpressionAd) && (
              <Ad
                type="page-impression"
                id="page_impression"
                isPageImpression
              />
            )}
            <Header isHomeTemplate={isHomeTemplate} layoutType={layoutType} {...{ ...props, client, config }} />
            {
              !hideOOPsAds
              && !isCommercialPage
              && !isCommercialPageTitleImage
              && getOOPAds()
            }
            <BelowHeaderAd />
            <Template {...props} />
            <Footer layoutType={layoutType} {...props} />
          </div>
        </PolarisApp>
      </>
    );
  };
  WrappedTemplate.propTypes = {
    adConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    client: PropTypes.shape().isRequired,
    config: PropTypes.shape(),
    dataLayer: PropTypes.shape().isRequired,
    layoutData: PropTypes.shape({
      page: PropTypes.shape({
        layoutType: PropTypes.string,
        fullWidth: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    metaData: PropTypes.shape().isRequired,
    permutive: PropTypes.shape(),
  };

  WrappedTemplate.defaultProps = {
    adConfig: {},
    config: {},
    permutive: {},
  };

  WrappedTemplate.displayName = 'withTemplate()';

  // Provides meta and config to template.
  return compose(withMeta, withPolaris, withViewport)(WrappedTemplate);
};

export default withTemplate;
